import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Dialog,
  // DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Container,
  Grid,
  Button,
  Typography,
  Box,
} from '@mui/material';
import styles from './style';
import ModalTitleWithCloseIcon from '../modalTitleWithCloseIcon';
import { updateTempState } from '../../../actions/temp';

const useStyles = makeStyles(styles);

const AlertDialog = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery('(min-width:600px)');

  // Custom Properties
  const {
    title,
    description,
    content,
    dismissiveAction,
    confirmingAction,
    acknowledgementAction,
    onClose,
    open: initialOpen = true,
    isBlocking,
    fullWidth,
    maxWidth = 'sm',
    allowFullscreen = true,
    noDefaultDismissiveAction,
  } = props;

  const [open, setOpen] = useState(initialOpen);
  const lastBrowserUrl = useSelector(state => state.temp.lastBrowserUrl);

  const neutralizeBack = callback => {
    if (lastBrowserUrl !== window.location.href) {
      window.history.pushState(null, '', window.location.href);
      dispatch(
        updateTempState.updateTempStateAction({ lastBrowserUrl: window.location.href }),
      );
    }
    window.onpopstate = () => {
      // window.history.pushState(null, '', window.location.href);
      callback();
    };
  };

  const revivalBack = () => {
    window.onpopstate = undefined;
  };

  const handleClose = () => {
    setOpen(false);
    revivalBack();
    dispatch(updateTempState.updateTempStateAction({ alertPopUp: null }));
    onClose();
  };

  useEffect(() => {
    if (open) {
      neutralizeBack(handleClose);
    }
  }, [open]);

  const defaultCloseDismissiveAction = (
    <Button
      className={classes.secondaryButton}
      sx={{ width: '6rem', mx: 1 }}
      onClick={() => handleClose()}
    >
      Close
    </Button>
  );

  const finalDismissiveAction =
    !dismissiveAction && !noDefaultDismissiveAction
      ? defaultCloseDismissiveAction
      : dismissiveAction;

  return (
    <Dialog
      fullScreen={allowFullscreen ? !isDesktop : false}
      onClose={() => {
        if (!isBlocking) {
          handleClose();
        }
      }}
      open={open}
      classes={{
        paper: classes.dialogContainer,
      }}
    >
      <Box sx={{ px: 2 }} className={classes.dialogContent}>
        <ModalTitleWithCloseIcon
          className={classes.headerTitle}
          classes={{ root: classes.dialogActions }}
          title={title}
          onClose={handleClose}
        />
      </Box>
      <DialogContent className={classes.dialogContent}>
        <Container maxWidth={maxWidth}>
          <Grid container spacing={1}>
            {description && (
              <Grid item xs={12}>
                <Typography sx={{ color: 'text.label' }} variant="body1">
                  {description}
                </Typography>
              </Grid>
            )}
            {content && (
              <Grid item xs={12}>
                {content}
              </Grid>
            )}
          </Grid>
        </Container>
      </DialogContent>

      {(finalDismissiveAction || confirmingAction || acknowledgementAction) && (
        <DialogActions className={classes.dialogActions}>
          {finalDismissiveAction}
          {confirmingAction}
          {acknowledgementAction}
        </DialogActions>
      )}
    </Dialog>
  );
};

AlertDialog.defaultProps = {
  onClose: () => {},
};

AlertDialog.propTypes = {
  // Dialog Properties
  onClose: PropTypes.func,

  // Custom Properties
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.string]),
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.string]),
  dismissiveAction: PropTypes.element,
  confirmingAction: PropTypes.element,
  acknowledgementAction: PropTypes.element,
};

export default AlertDialog;

/*
  dialogProps: {
    open: true,
    onClose: () => {},
  },
  */
