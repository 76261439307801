import {
  UPDATE_RECEIPTS_CURRENT_CACHE_KEY,
  GET_RECEIPTS_API_REQUEST,
  GET_RECEIPTS_API_SUCCESS,
  GET_RECEIPTS_API_ERROR,
  GET_NEXT_RECEIPTS_SUCCESS,
} from '../../../constants/receiptActionNames';
import { createRelativeUrl } from '../../../utils/functions';
import { checkCacheValid } from '../../../utils/functions/cache';
import { getReceiptsUrl } from '../../../apis/receipts/getReceiptsApi';
import { getReceiptsApi } from '../../../apis/receipts';

function updateCacheKey(cacheKey) {
  return {
    type: UPDATE_RECEIPTS_CURRENT_CACHE_KEY,
    payload: cacheKey,
  };
}

function getReceiptsRequest() {
  return {
    type: GET_RECEIPTS_API_REQUEST,
    payload: true,
  };
}
function getReceiptsSuccess(receipts, params, cacheKey) {
  return {
    type: GET_RECEIPTS_API_SUCCESS,
    payload: receipts,
    params,
    cacheKey,
  };
}

function getReceiptsError(err) {
  return {
    type: GET_RECEIPTS_API_ERROR,
    payload: err,
  };
}

export function getReceiptsAction(params) {
  return (dispatch, getState) => {
    const state = getState();
    params.alpha2 = state?.locations?.yourLocation?.alpha2;
    const cacheKey = createRelativeUrl({
      path: getReceiptsUrl,
      queryObject: params,
    });

    const isCacheValid = checkCacheValid(getState, 'receipts', cacheKey);
    if (isCacheValid) {
      // dispatch(getClaimsSuccess(null, params, cacheKey, filteredLobCategories));
      dispatch(updateCacheKey(cacheKey));
      return Promise.resolve(null);
    }

    dispatch(getReceiptsRequest());
    return getReceiptsApi
      .getReceipts(state, params)
      .then(data => {
        dispatch(getReceiptsSuccess(data, params, cacheKey));
        return data;
      })
      .catch(error => {
        dispatch(getReceiptsError(error));
        throw error;
      });
  };
}

function getNextReceiptsSuccess(prod) {
  return {
    type: GET_NEXT_RECEIPTS_SUCCESS,
    payload: prod,
  };
}

export function getNextReceiptsAction(data) {
  return getNextReceiptsSuccess(data);
}

export default {
  getReceiptsAction,
  getNextReceiptsAction,
};
