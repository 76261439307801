import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
// import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  // Grid,
  // Typography,
  // IconButton,
  Slide,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ModalTitleWithCloseIcon from '../modalTitleWithCloseIcon';
import styles from './style';
import { useSmall, useRouter } from '../../../hooks';
import { ContentDisplay, ContentProseDisplay } from '../../common';
import { updateTempState } from '../../../actions/temp';
// import { scrollStyles } from '../../../styles';

const useStyles = makeStyles(styles);
// const useScrollStyles = makeStyles(scrollStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DetailsModal = ({
  onClose,
  content,
  title,
  open = true,
  className,
  fullScreen = false,
  fullWidth = true,
  modalSize = 'sm',
  sx,
  proseMirrorContent,
  richTextContent,
  onBackClick,
  showBackButton,
}) => {
  const isSmall = useSmall();
  const router = useRouter();
  const dispatch = useDispatch();
  const classes = useStyles({ isSmall, modalSize });

  // const scrollClasses = useScrollStyles();
  // , scrollClasses.scrollbar
  // const dispatch = useDispatch();

  const lastBrowserUrl = useSelector(state => state.temp.lastBrowserUrl);

  const neutralizeBack = callback => {
    if (lastBrowserUrl !== window.location.href) {
      window.history.pushState(null, '', window.location.href);
      dispatch(
        updateTempState.updateTempStateAction({ lastBrowserUrl: window.location.href }),
      );
    }
    window.onpopstate = () => {
      // window.history.pushState(null, '', window.location.href);
      callback();
    };
  };

  const revivalBack = () => {
    window.onpopstate = undefined;
  };

  const handleClose = () => {
    revivalBack();
    onClose();
  };

  useEffect(() => {
    if (open) {
      neutralizeBack(handleClose);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      // onClose={onClose}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      scroll="body"
      fullWidth={fullWidth}
      fullScreen={isSmall || fullScreen}
      classes={{ paper: classnames(classes.dialog, className) }}
      maxWidth={modalSize}
    >
      <ModalTitleWithCloseIcon
        className={classes.headerTitle}
        classes={{ root: classes.dialogActions }}
        title={title}
        onClose={handleClose}
        sx={sx}
        showBackButton={showBackButton}
        onBackClick={onBackClick}
      />

      <DialogContent className={classnames(classes.root)} sx={sx}>
        {richTextContent && richTextContent?.length >= 10 && (
          <ContentDisplay sx={{ py: 1, px: 3 }} content={richTextContent} />
        )}
        {proseMirrorContent && proseMirrorContent?.length >= 10 && (
          <ContentProseDisplay sx={{ py: 1, px: 3 }} content={proseMirrorContent} />
        )}
        {content}
      </DialogContent>
      <DialogActions
        classes={{
          root: classnames(classes.dialogActions, { [classes.pinToBottom]: fullScreen }),
        }}
        sx={sx}
      >
        <Button
          sx={{ width: 'auto' }}
          onClick={handleClose}
          className={classes.secondaryButton}
          align="right"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DetailsModal;

/*
      <DialogTitle id="alert-dialog-title">
        <Grid container direction="row" alignItems="center" justifyContent="center">
          <Grid item xs={10} align="left">
            <Typography variant="h6">{title}</Typography>
          </Grid>
          <Grid item xs={2} align="right">
            {onClose ? (
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </Grid>
        </Grid>
      </DialogTitle>
      */
