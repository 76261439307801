import React from 'react';
import { Grid, Skeleton, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { getRef, uuidv4 } from '../../../../utils/functions';
import ProductCategoryCard from '../../../cards/categoryCard/dutchCategoryCard';
import { useSmall } from '../../../../hooks';
import style from '../style';

const useStyles = makeStyles(style);

function ProductsList({
  products,
  isAction,
  mobileColumnWidth = 6,
  desktopColumnWidth = 4,
  loading,
}) {
  // console.log('Products are ', products);
  const theme = useTheme();
  const isSmall = useSmall();
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={isSmall ? 1 : 2}
      direction="row"
      justifyContent="center"
      className={classes.dutchItemContainer}
    >
      {(loading ? Array.from(new Array(4)) : products).map(product => {
        // console.log('product: ', product);
        const newProduct = {
          ...product,
          styles: {
            textColor: theme.palette.text.bright,
            justifyContent: 'space-between',
            padding: theme.spacing(5),
            titleFontSize: theme.typography.h4.fontSize,
            subTitleFontSize: theme.typography.subtitle2.fontSize,
            titleTextColor: product?.textColor,
            subTitleTextColor: product?.subTitleColor,
            buttonBorder:
              product?.background === theme?.palette?.background?.default
                ? `1px solid ${theme?.palette?.primary?.main}`
                : 'none',
          },
        };
        if (product) {
          return (
            <Grid
              key={newProduct?.lob || uuidv4()}
              item
              className={classes.dutchItem}
            >
              <ProductCategoryCard
                product={newProduct}
                isAction={isAction}
                loading={loading}
              />
            </Grid>
          );
        }
        return <Skeleton animation="wave" variant="rectangle" width={250} height={250} />;
      })}
    </Grid>
  );
}

export default ProductsList;
