import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  Card,
  Typography,
  Box,
  Grid,
  useMediaQuery,
  Divider,
  Button,
  IconButton,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { listSelector } from '../../../selectors';
import { MainPanel, AsidePanel } from '../../../componentsV2/listDetails';
import { ObjectList } from '../../../componentsV2/list';
import { DetailsModal } from '../../../componentsV2/modal';
import {
  clientsIndexName,
  motorLob,
  healthLob,
  homeLob,
  lifeLob,
  otherLob,
  pensionLob,
  investmentLob,
  agentType,
  domain,
  dutchTemplate,
} from '../../../utils/variables';
import { parseBool, getRef, sourceTextFromData } from '../../../utils/functions';
import { Loading, ErrorAlert, Header } from '../../../componentsV2/common';
import {
  useRouter,
  useClient,
  useSmall,
  useVariables,
  useFeatureToggle,
} from '../../../hooks';
import { addWizard } from '../../../actions/wizards';
import routes from '../../../utils/variables/routes';
import { getSingleClient } from '../../../actions/clients';
import { selectedClient } from '../../../actions/auth';
import styles from './style';
import fatumRedirectApi from '../../../apis/auth/getFatumRedirectApi';

const useStyles = makeStyles(styles);
const ClientDetails = ({ match, client: initialClient, onClose = () => {} }) => {
  const history = useHistory();
  const state = useSelector(state => state);
  const classes = useStyles();
  const dispatch = useDispatch();
  const router = useRouter();
  const isSmall = useSmall();
  const variables = useVariables();
  const doFeatureToggle = useFeatureToggle();
  const useFatumClients = doFeatureToggle('useFatumClients');
  const clientId = match?.params?.id || initialClient?.masterId;
  const storeClient = useSelector(state =>
    listSelector.listFind(state, clientId, clientsIndexName),
  );
  const storeClientWithC360Id = useSelector(state =>
    state.clients?.allRows?.find(x => x.c360Id == clientId),
  );
  const singleClient = useSelector(state => state.clients.client);
  const selectedClientDetails = useSelector(
    state => state?.auth?.selectedClient || state?.clients?.client,
  );
  const customerMasterId = selectedClientDetails?.masterId;
  const accessToken = useSelector(state => state?.auth?.user?.accessToken);
  const isAgent =
    useSelector(
      state => state?.auth?.user?.agent?.id && state?.auth?.usertype === agentType,
    ) && accessToken;
  const referralId = useSelector(state => {
    return state.temp.referralId;
  });
  const isError = useSelector(state => state.clients?.isError);
  const isSuccess = useSelector(state => state.clients?.isSuccess);
  const isPending = useSelector(state => state.clients.isPending);

  const domains = {
    ...domain,
    ...(variables?.domains || {}),
  };

  const agentPortalTakeInsuranceUrl = sourceTextFromData(
    { domains },
    variables?.fatumRedirectLinks?.agentPortalTakeInsurance,
  );

  const agentPortalQuoteURL = sourceTextFromData(
    { domains },
    variables?.fatumRedirectLinks?.agentPortalQuoteURL,
  );

  const ggfatumLink = sourceTextFromData(
    { domains },
    variables?.fatumRedirectLinks?.quote,
  );

  const checkedProduct = useSelector(state => state?.auth?.selectedProduct);
  const response = useSelector(state => state.clients.response);
  const error = useSelector(state => state.claims.error);
  const client = initialClient || storeClient || storeClientWithC360Id || singleClient;

  useEffect(() => {
    if (!client) {
      const params = { id: clientId };
      dispatch(getSingleClient.getSingleClientAction(params));
    }
  }, [client]);

  if (isPending) {
    return <Loading secondaryTitle="Getting your Client Details" />;
  }

  const handleClick = () => {
    dispatch(selectedClient.selectedClientSuccess(client));
    onClose();
  };

  const getAQuoteHandleClick = async () => {
    dispatch(selectedClient.selectedClientSuccess(client));
    if (useFatumClients) {
      let sessionId = null;
      const redirectURL = new URL(isAgent ? `${agentPortalQuoteURL}` : '');

      if (checkedProduct?.productCode) {
        redirectURL.searchParams.append('product', checkedProduct?.productCode);
      }
      if (accessToken) {
        const res = await fatumRedirectApi.getSessionId(state, { customerMasterId });
        sessionId = res?.payload?.sessionId;
        if (sessionId) {
          redirectURL.searchParams.append('sessionid', sessionId);
        }
      }
      if (referralId) {
        redirectURL.searchParams.append('360_referrer', referralId);
      }
      window.location.href = redirectURL;
    }
    onClose();
  };

  let errorHeaders = null;
  let errorCode = null;
  if (isSuccess) {
    errorHeaders = response?.headers;
    errorCode = response?.statusCode || response?.responseDesc?.code;
  } else if (isError) {
    errorHeaders = error?.headers;
    errorCode = error?.status || error?.statusCode || error?.responseDesc?.code;
  }

  if (!client && !isPending) {
    return (
      <Grid container justify="center" alignItems="center" direction="row" spacing={2}>
        <Grid item xs={12} md={12}>
          <ErrorAlert
            isError={isError}
            infoTitle="No Client Available"
            errorTitle="Error retrieving Client Item"
            infoMessage="Sorry, this client item was not found."
            errorMessage="Error retrieving this client item. Please try again."
            gridSize={6}
            headers={errorHeaders}
            statusCode={errorCode}
          />
        </Grid>
      </Grid>
    );
  }

  client.clientName = `${client?.firstName || ''} ${client?.lastName}`;

  const mainContent = (
    <Grid container>
      <Grid item xs={12}>
        <ObjectList
          title="General Information"
          textAlign="left"
          columns={[
            { dataKey: 'clientNumber', header: 'Client ID' },

            { dataKey: 'clientName', header: 'Name' },
            { dataKey: 'dateOfBirth', header: 'Date of Birth' },
            { dataKey: 'gender', header: 'Gender' },
            { dataKey: 'maritalStatus', header: 'Marital Status' },
          ]}
          data={client}
          renderType="list"
        />
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <ObjectList
          title="Contact Information"
          textAlign="left"
          columns={[
            { dataKey: 'email', header: 'Email' },
            { dataKey: 'phones.res', header: 'Home' },
            { dataKey: 'phones.mob', header: 'Phone' },
            { dataKey: 'phones.bus', header: 'Business' },
          ]}
          data={client}
          renderType="list"
        />
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <ObjectList
          title="Address Information"
          textAlign="left"
          columns={[{ dataKey: 'fullResidentialAddress', header: 'Residential' }]}
          data={client}
          renderType="list"
        />
        <Divider />
      </Grid>
    </Grid>
  );

  const asideContent = (
    <Grid
      container
      spacing={4}
      alignItems="center"
      justifyContent="center"
      sx={{ p: 3, minHeight: '12rem' }}
    >
      <Grid item xs={12} align="left">
        <Typography sx={{ fontWeight: 'bold', color: 'text.bright' }}>Actions</Typography>
      </Grid>
      <Grid item xs={12} md={12} align="center">
        {customerMasterId && (
          <Button
            className={classes.invertContained}
            fullWidth
            component={RouterLink}
            to={routes.portfolio.portfolioSummary}
            onClick={handleClick}
          >
            View Client Policies
          </Button>
        )}
      </Grid>
      {!useFatumClients && (
        <Grid item xs={12} md={12} align="center">
          <Button
            className={classes.invertContained}
            fullWidth
            component={RouterLink}
            to={routes.claims.index}
            onClick={handleClick}
          >
            View Client Claims
          </Button>
        </Grid>
      )}
      <Grid item xs={12} md={12} align="center">
        <Button
          className={classes.invertContained}
          fullWidth
          component={RouterLink}
          to={routes.quotes.index}
          onClick={handleClick}
        >
          View Client Quotes
        </Button>
      </Grid>

      <Grid item xs={12} md={12} align="center">
        <Button
          className={classes.invertContained}
          fullWidth
          component={RouterLink}
          to={routes.wizards.quote}
        >
          Get a Quote
        </Button>
      </Grid>
      {useFatumClients && (
        <Grid item xs={12} md={12} align="center">
          <Button
            className={classes.invertContained}
            fullWidth
            onClick={() => {
              history.push(routes.products.index);
            }}
          >
            Shop
          </Button>
        </Grid>
      )}
    </Grid>
  );

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="stretch">
      <Grid item xs={12} sx={{ height: 'auto' }}>
        <Header
          seoTitle={`Client #${clientId?.toUpperCase()}`}
          title="Client Details"
          subTitle="A detailed overview of your selected client."
        />
      </Grid>
      <Grid item xs={12} md={7} sx={{ height: 'auto' }}>
        <MainPanel
          style={{ borderRadius: isSmall ? '3px 3px 0px 0px' : '3px 0px 0px 3px' }}
        >
          {mainContent}
        </MainPanel>
      </Grid>
      <Grid item xs={12} md={5} sx={{ height: 'auto' }}>
        <AsidePanel
          style={{ borderRadius: isSmall ? '0px 0px 3px 3px' : '0px 3px 3px 0px' }}
        >
          {asideContent}
        </AsidePanel>
      </Grid>
    </Grid>
  );
};

export default ClientDetails;

/*

      */
