import {
  UPDATE_CLIENT_POLICIES_CURRENT_CACHE_KEY,
  GET_CLIENTSPOLICIES_API_ERROR,
  GET_CLIENTSPOLICIES_API_REQUEST,
  GET_CLIENTSPOLICIES_API_SUCCESS,
  GET_NEXT_CLIENTSPOLICIES_SUCCESS,
} from '../../../constants/clientsPoliciesActionNames';
import { createRelativeUrl } from '../../../utils/functions';
import { checkCacheValid } from '../../../utils/functions/cache';
import { clientCachePoliciesUrl } from '../../../apis/clientspolicies/getClientsPoliciesApi';
import { getClientPoliciesApi } from '../../../apis/clientspolicies';

function updateCacheKey(cacheKey) {
  return {
    type: UPDATE_CLIENT_POLICIES_CURRENT_CACHE_KEY,
    payload: cacheKey,
  };
}

function getClientPoliciesRequest(body) {
  return {
    type: GET_CLIENTSPOLICIES_API_REQUEST,
    payload: true,
    body,
  };
}

function getClientPoliciesSuccess(clientPolicies, body, cacheKey, lobTypes) {
  return {
    type: GET_CLIENTSPOLICIES_API_SUCCESS,
    payload: clientPolicies,
    body,
    cacheKey,
    lobTypes,
  };
}

function getClientPoliciesError(err, body, lobTypes) {
  return {
    type: GET_CLIENTSPOLICIES_API_ERROR,
    payload: err,
    body,
    lobTypes,
  };
}

export function getClientPoliciesAction(params, body) {
  // const response = getPayloadResponse();
  return (dispatch, getState) => {
    const state = getState();
    const lobTypes = state?.locations?.lobCategories;
    const cacheParams = {
      ...params,
      infiniteScroll: body?.infiniteScroll,
      alpha2: body?.alpha2,
    };
    const cacheKey = createRelativeUrl({
      path: clientCachePoliciesUrl,
      queryObject: cacheParams,
    });

    const isCacheValid = checkCacheValid(getState, 'clientPolicies', cacheKey);
    if (isCacheValid) {
      // dispatch(getClaimsSuccess(null, params, cacheKey, filteredLobCategories));
      dispatch(updateCacheKey(cacheKey));
      return Promise.resolve(null);
    }

    dispatch(getClientPoliciesRequest(body));
    return getClientPoliciesApi
      .getClientsPolicies(state, params)
      .then(data => {
        dispatch(getClientPoliciesSuccess(data, body, cacheKey, lobTypes));
        return data;
      })
      .catch(error => {
        dispatch(getClientPoliciesError(error, body));
        throw error;
        // return response;
      });
  };
}

function getNextClientPoliciesSuccess(prod) {
  return {
    type: GET_NEXT_CLIENTSPOLICIES_SUCCESS,
    payload: prod,
  };
}

export function getNextClientPoliciesAction(data) {
  return getNextClientPoliciesSuccess(data);
}

export default { getClientPoliciesAction, getNextClientPoliciesAction };
