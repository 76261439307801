import { useDispatch, useSelector } from 'react-redux';
import useRouter from './useRouter';
import routes from '../utils/variables/routes';
import Cookies from '../cookies/cookies';
import {
  manuallyChosenAlpha2Storage,
  userSettingsExpireTimeHrs,
} from '../utils/variables';

const useCountryChange = () => {
  const router = useRouter();
  const allLocations = useSelector(state => state.locations.allLocations);

  return newAlpha2 => {
    const newCountryValue = newAlpha2;
    const newLocation = allLocations?.find(x => x.alpha2 === newCountryValue);

    if (newLocation) {
      Cookies.setCookie(
        manuallyChosenAlpha2Storage,
        newCountryValue,
        userSettingsExpireTimeHrs,
      );
      setTimeout(function() {
        router.replace(`${routes.home}?c=${newAlpha2}`);
      }, 50);
    } else {
      window.alert('No Valid Territory Selected');
    }
  };
};

export default useCountryChange;
