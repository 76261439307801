import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getFatumSessionId } from '../../../actions/auth';
import { useFeatureToggle, useVariables } from '../../../hooks';

const FatumSessionGenerator = () => {
  const dispatch = useDispatch();
  const doFeatureToggle = useFeatureToggle();
  const variables = useVariables();
  const expireTime = variables?.fatumSession?.expireTime || 570;

  const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated);
  const useFatumLogin = doFeatureToggle('useFatumLogin');
  let timer;

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearInterval(timer);
  };

  // this function sets the timer that logs out the user after 10 secs
  const handleExpireTimer = () => {
    resetTimer(); // Reset the timer
    dispatch(getFatumSessionId.getFatumSessionIdAction());
    timer = setInterval(() => {
      // logs out user
      console.log('running timer: ');
      if (useFatumLogin && isAuthenticated) {
        dispatch(getFatumSessionId.getFatumSessionIdAction());
      }
    }, expireTime * 1000);
  };

  // when component mounts or isAuthenticated changes, it adds or removes event listeners to the window
  useEffect(() => {
    if (isAuthenticated && useFatumLogin) {
      // Start the timer initially
      handleExpireTimer();
    } else {
      // Clear the timer and remove event listeners when not authenticated
      resetTimer();
    }

    // Cleanup: remove event listeners when the component unmounts or isAuthenticated changes
    return () => {
      resetTimer(); // Clear the timer on unmount or isAuthenticated changes
    };
  }, [isAuthenticated, useFatumLogin]);

  return <div />;
};

export default FatumSessionGenerator;
