import React from 'react';
import {
  // Container,
  Grid,
  Typography,
  Button,
  Paper,
  // Divider,
  // Link,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from './style';

const useStyles = makeStyles(styles);

const EmptyBox = ({ description, buttons }) => {
  const classes = useStyles();
  return (
    <Paper elevation={3} className={classes.paper}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        {description && (
          <Grid item xs={12} md={10}>
            <Typography
              variant="body1"
              sx={{
                color: 'text.primary',
              }}
              align="center"
            >
              {description}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} md={10}>
          <Grid container spacing={1}>
            {buttons?.map(button => {
              return (
                <Grid item xs={12}>
                  {button}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EmptyBox;
