import { DEFAULT_KEY, generateCacheTTL } from '../../utils/functions/cache';
import {
  GET_NOTIFICATION_API_REQUEST,
  GET_NOTIFICATION_API_SUCCESS,
  GET_NOTIFICATION_API_ERROR,
  GET_NEW_NOTIFICATION_API_REQUEST,
  GET_NEW_NOTIFICATION_API_SUCCESS,
  GET_NEW_NOTIFICATION_API_ERROR,
  MARK_NOTIFICATION_AS_VIEWED_API_REQUEST,
  MARK_NOTIFICATION_AS_VIEWED_API_SUCCESS,
  MARK_NOTIFICATION_AS_VIEWED_API_ERROR,
  MARK_NOTIFICATION_AS_READ_API_REQUEST,
  MARK_NOTIFICATION_AS_READ_API_SUCCESS,
  MARK_NOTIFICATION_AS_READ_API_ERROR,
  CREATE_NOTIFICATION_API_REQUEST,
  CREATE_NOTIFICATION_API_SUCCESS,
  CREATE_NOTIFICATION_API_ERROR,
} from '../../constants/notificationActionNames';
import {
  isoStringToDate,
  dateTimeToString,
  getKeys,
  generateSearchWords,
} from '../../utils/functions';
import { statusOk } from '../../utils/variables';

const notificationData = [
  {
    _id: '5ebd9bce3f56d53e3ca9547a',
    title: 'Premiums due',
    body: 'Your Xpress Life policy has pending premiums to be paid.',
    viewed: false,
    type: 'warning',
    link: '/portfolio',
    timestamp: '2020-05-14T19:28:14.514Z',
    read: false,
  },
  {
    _id: '5ebd9be83f56d53e3ca9547b',
    title: 'Complete your profile',
    body: 'Please navigate to your profile to fill out the missing fields.',
    viewed: true,
    type: 'information',
    link: '/profile',
    timestamp: '2020-05-14T19:28:40.671Z',
    read: false,
  },
];
// let searchKeys = [];

export const initialState = {
  // searchKeys,
  notificationResponse: null, // { payload: { notifications: notificationData, count: 2 } },
  isNotificationPending: false,
  isNotificationError: false,
  isNotificationSuccess: false,
  notificationError: null,
  notifications: [],
  notificationCount: 0,

  newNotificationResponse: null, // { payload: { notifications: notificationData, count: 2 } },
  isNewNotificationPending: false,
  isNewNotificationError: false,
  isNewNotificationSuccess: false,
  newNotificationError: null,
  newNotifications: [], // notificationData,
  newNotificationCount: 0,

  createNotificationResponse: null,
  isCreateNotificationPending: false,
  isCreateNotificationError: false,
  isCreateNotificationSuccess: false,
  createNotificationError: null,

  markNotificationAsReadResponse: null,
  isMarkNotificationAsReadPending: false,
  isMarkNotificationAsReadError: false,
  isMarkNotificationAsReadSuccess: false,
  markNotificationAsReadError: null,

  markNotificationAsViewedResponse: null,
  isMarkNotificationAsViewedPending: false,
  isMarkNotificationAsViewedError: false,
  isMarkNotificationAsViewedSuccess: false,
  markNotificationAsViewedError: null,
  [DEFAULT_KEY]: null,
  currentCacheKey: '',
};

const createNotifications = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  const notifications = payloadArray.map((object, index) => {
    /*
    if (index === 0) {
      const searchWords = generateSearchWords(object);
      const newKeys = getKeys(searchWords);
      searchKeys = [...searchKeys, ...newKeys];
      // console.log('searchWords: ', searchWords);
    }
   */

    const position = index;
    return {
      index: position,
      timestamp: dateTimeToString(isoStringToDate(object?.timestamp)),
      id: object?._id || index,
      title: object?.title,
      body: object?.body,
      viewed: object?.viewed,
      type: object?.type,
      link: object?.link,
      read: object?.read,
    };
  });
  // // console.log('notifications: ', notifications);

  /*
  notifications.sort((a, b) => {
    // // console.log('b: ', b);
    // // console.log('a: ', a);
    return b.timestamp.localeCompare(a.timestamp); // a.timestamp >= b.timestamp; // a.id - b.id ||
  });
  */
  // // console.log('finalNotifications: ', finalNotifications);
  // // console.log('notifications: ', notifications);

  return notifications;
};

function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_NOTIFICATION_API_REQUEST:
      return {
        ...state,
        isNotificationPending: true,
        isNotificationError: false,
        isNotificationSuccess: false,
      };
    case GET_NOTIFICATION_API_SUCCESS: {
      const notifications = action?.payload?.payload?.notifications || [];

      const finalNotifications = createNotifications(notifications);

      const count = finalNotifications.length > 0 ? finalNotifications.length : 0; // action?.payload?.payload?.count || 0;
      // // console.log('notifications: ', notifications);

      const response = action?.payload;
      const isError = response?.responseType !== statusOk;
      response.payload = null;
      const cacheKey = action?.cacheKey;

      return {
        ...state,
        // searchKeys,
        isNotificationPending: false,
        isNotificationError: isError,
        isNotificationSuccess: true,
        notificationResponse: response,
        notifications: finalNotifications,
        notificationCount: count,
        currentCacheKey: cacheKey,
        [DEFAULT_KEY]: {
          ...(state[DEFAULT_KEY] || {}),
          [cacheKey]: generateCacheTTL(),
        },
      };
    }
    case GET_NOTIFICATION_API_ERROR:
      return {
        ...state,
        isNotificationPending: false,
        isNotificationError: true,
        isNotificationSuccess: false,
        // notificationError: action.payload,
      };
    case GET_NEW_NOTIFICATION_API_REQUEST:
      return {
        ...state,
        isNewNotificationPending: true,
        isNewNotificationError: false,
        isNewNotificationSuccess: false,
      };
    case GET_NEW_NOTIFICATION_API_SUCCESS: {
      const newNotifications = action?.payload?.payload?.notifications || [];
      // const count = action?.payload?.payload?.count || 0;
      const finalNewNotifications = createNotifications(newNotifications);
      const count = finalNewNotifications.length > 0 ? finalNewNotifications.length : 0;

      const response = action?.payload;
      const isError = response?.responseType !== statusOk;
      response.payload = null;
      const cacheKey = action?.cacheKey;

      return {
        ...state,
        isNewNotificationPending: false,
        isNewNotificationError: isError,
        isNewNotificationSuccess: true,
        newNotificationResponse: response,
        newNotifications: finalNewNotifications,
        newNotificationCount: count,
        [DEFAULT_KEY]: {
          ...(state[DEFAULT_KEY] || {}),
          [cacheKey]: generateCacheTTL(),
        },
      };
    }
    case GET_NEW_NOTIFICATION_API_ERROR:
      return {
        ...state,
        isNewNotificationPending: false,
        isNewNotificationError: true,
        isNewNotificationSuccess: false,
        // newNotificationError: action.payload,
      };
    case MARK_NOTIFICATION_AS_VIEWED_API_REQUEST:
      return {
        ...state,
        isMarkNotificationAsViewedPending: true,
        isMarkNotificationAsViewedError: false,
        isMarkNotificationAsViewedSuccess: false,
      };
    case MARK_NOTIFICATION_AS_VIEWED_API_SUCCESS: {
      const params = action?.params;
      const { newNotifications } = state;
      const { notificationId } = params;
      const newNotification = newNotifications.find(x => x.id === notificationId) || {};
      newNotification.viewed = true;
      newNotification.read = true;
      const index = newNotifications.findIndex(x => x.id === notificationId);
      newNotifications[index] = newNotification;
      const newNotificationCount = newNotifications.filter(x => !x.read).length;

      const response = action?.payload;
      response.payload = null;
      return {
        ...state,
        isMarkNotificationAsViewedPending: false,
        isMarkNotificationAsViewedError: false,
        isMarkNotificationAsViewedSuccess: true,
        markNotificationAsViewedResponse: response,
        newNotifications,
        newNotificationCount,
        [DEFAULT_KEY]: null,
      };
    }
    case MARK_NOTIFICATION_AS_VIEWED_API_ERROR:
      return {
        ...state,
        isMarkNotificationAsViewedPending: false,
        isMarkNotificationAsViewedError: true,
        isMarkNotificationAsViewedSuccess: false,
        // markNotificationAsViewedError: action.payload,
      };
    case MARK_NOTIFICATION_AS_READ_API_REQUEST:
      return {
        ...state,
        isMarkNotificationAsReadPending: true,
        isMarkNotificationAsReadError: false,
        isMarkNotificationAsReadSuccess: false,
      };
    case MARK_NOTIFICATION_AS_READ_API_SUCCESS: {
      const { newNotifications } = state;
      const notifications =
        newNotifications.map(_x => {
          const x = { ..._x };
          x.read = true;
          return x;
        }) || {};

      const response = action?.payload;
      response.payload = null;
      return {
        ...state,
        isMarkNotificationAsReadPending: false,
        isMarkNotificationAsReadError: false,
        isMarkNotificationAsReadSuccess: true,
        markNotificationAsReadResponse: response,
        newNotificationCount: 0,
        newNotifications: notifications,
        [DEFAULT_KEY]: null,
      };
    }
    case MARK_NOTIFICATION_AS_READ_API_ERROR:
      return {
        ...state,
        isMarkNotificationAsReadPending: false,
        isMarkNotificationAsReadError: true,
        isMarkNotificationAsReadSuccess: false,
        // markNotificationAsReadError: action.payload,
      };

    case CREATE_NOTIFICATION_API_REQUEST:
      return {
        ...state,
        isCreatingNotificationPending: true,
        isCreatingNotificationError: false,
        isCreatingNotificationSuccess: false,
      };
    case CREATE_NOTIFICATION_API_SUCCESS: {
      const response = action?.payload;
      response.payload = null;
      return {
        ...state,
        isCreatingNotificationPending: false,
        isCreatingNotificationError: false,
        isCreatingNotificationSuccess: true,
        createNotificationResponse: response,
      };
    }
    case CREATE_NOTIFICATION_API_ERROR:
      return {
        ...state,
        isCreatingNotificationPending: false,
        isCreatingNotificationError: true,
        isCreatingNotificationSuccess: false,
        // createNotificationError: action.payload,
      };
    default:
      return state;
  }
}
export default notificationsReducer;
