import React from 'react';
import classnames from 'classnames';
import {
  List,
  ListItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Alert,
  ListItemText,
  Typography,
  Grid,
  ListSubheader,
  Divider,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  uuidv4,
  getRef,
  formatDataTableValue,
  dateToString,
  stringToDate,
} from '../../../utils/functions';
import { CustomTooltip } from '../../common';

const useStyles = makeStyles(theme => ({
  title: ({ textColor }) => ({
    fontWeight: 'bold !important',
    fontSize: `${theme.typography.body1.fontSize} !important`,
    color: `${getRef(theme.palette, textColor)} !important`,
  }),
  subTitle: ({ textColor }) => ({
    fontWeight: '600 !important',
    fontSize: `${theme.typography.subtitle2.fontSize} !important`,
    color: `${getRef(theme.palette, textColor)} !important`,
  }),
  primaryText: ({ textColor }) => ({
    fontWeight: '600 !important',
    fontSize: `${theme.typography.subtitle2.fontSize} !important`,
    color: `${getRef(theme.palette, textColor)} !important`,
  }),
  secondaryText: ({ textColor }) => ({
    fontSize: `${theme.typography.subtitle2.fontSize} !important`,
    color: `${getRef(theme.palette, textColor)} !important`,
  }),
}));

const ObjectList = props => {
  const {
    listClassName,
    itemClassName,
    data,
    renderItem,
    renderType,
    columns,
    layoutConfig,
    listAlign = 'horizontal',
    spacing,
    title,
    textAlign,
    textColor = 'text.label',
    subTitle,
    emptyMessage,
    fontSize = '0.875rem',
    noUnitsRef,
    showDivider = false,
    leftMargin = 1,
    noRef,
    noCols,
    fullWidth = false,
    notices,
    sx,
  } = props;

  const classes = useStyles({ textColor });
  // console.log('before data: ', data);
  if (!data || (Array.isArray(data) && data.length === 0)) {
    // console.log('data: ', data);
    return (
      <>
        {(title || subTitle) && (
          <Typography align={textAlign || 'left'} className={classes.title}>
            {title}
            {subTitle && (
              <Typography align={textAlign} className={classes.subTitle}>
                {subTitle}
              </Typography>
            )}
          </Typography>
        )}

        <Alert severity="info">{emptyMessage || 'No Data Available'}</Alert>
      </>
    );
  }

  const listData = Array.isArray(data) ? data : columns;

  if (renderType === 'table' && columns && columns.length > 0 && data.length > 0) {
    return (
      <TableContainer>
        {(title || subTitle) && (
          <Grid item xs={12}>
            <Typography align={textAlign || 'left'} className={classes.title}>
              {title}
              {subTitle && (
                <Typography align={textAlign} className={classes.subTitle}>
                  {subTitle}
                </Typography>
              )}
            </Typography>
            {showDivider && <Divider />}
          </Grid>
        )}
        <Table sx={{ pl: leftMargin }}>
          <TableHead>
            <TableRow>
              {columns?.map(column => {
                return (
                  <TableCell
                    sx={{
                      color: textColor,
                      fontWeight: 700,
                      fontSize,
                      textAlign: 'center',
                    }}
                    key={uuidv4()}
                  >
                    {column?.header}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map(row => (
              <TableRow key={uuidv4()}>
                {columns?.map(column => {
                  const colRenderItem = column?.renderItem;
                  if (colRenderItem) {
                    return (
                      <TableCell
                        key={uuidv4()}
                        sx={{ color: textColor, fontSize, textAlign: 'center' }}
                      >
                        {colRenderItem && colRenderItem(row)}
                      </TableCell>
                    );
                  }
                  let displayValue = null;
                  if (column.isCurrency) {
                    displayValue = formatDataTableValue({
                      inputValue:
                        noRef || column?.noRef
                          ? column?.dataKey
                          : getRef(row, column.dataKey),
                      units:
                        noUnitsRef || column?.noUnitsRef
                          ? column.units
                          : getRef(row, column.units),
                      isCurrency: true,
                      isNumeric: true,
                      isLocalize: true,
                      showDataUnits: true,
                    })?.dataValue;
                  } else if (column.isDate) {
                    displayValue = dateToString(
                      stringToDate(getRef(row, column.dataKey)),
                    );
                  } else {
                    displayValue =
                      noRef || column?.noRef
                        ? column.dataKey
                        : getRef(row, column.dataKey);
                  }
                  return (
                    <TableCell
                      key={uuidv4()}
                      sx={{ color: textColor, fontSize, textAlign: 'center' }}
                    >
                      {displayValue}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {notices}
      </TableContainer>
    );
  }
  if (renderType === 'gridList') {
    return (
      <Grid
        container
        display="block"
        direction="row"
        sx={sx || { px: 1 }}
        spacing={spacing || 1}
      >
        {(title || subTitle) && (
          <Grid item xs={12} sx={{ width: '100%' }}>
            <Typography
              align={textAlign || 'left'}
              className={classes.title}
              sx={{ color: textColor }}
            >
              {title}
              {subTitle && (
                <Typography align={textAlign} className={classes.subTitle}>
                  {subTitle}
                </Typography>
              )}
            </Typography>
            {showDivider && <Divider />}
          </Grid>
        )}
        <Box sx={{ pl: leftMargin, width: '100% !important' }}>
          <Grid
            container
            direction="row"
            spacing={1}
            sx={{ width: fullWidth ? '100% !important' : 'auto' }}
          >
            {listData?.map((item, index) => {
              return (
                <Grid
                  item
                  key={uuidv4()}
                  sm={layoutConfig?.sm || 12}
                  md={layoutConfig?.md || 12}
                  lg={layoutConfig?.lg || 12}
                  sx={{ width: fullWidth ? '100% !important' : 'auto' }}
                >
                  {renderItem && renderItem(item)}
                </Grid>
              );
            })}
          </Grid>
        </Box>
        {notices}
      </Grid>
    );
  }
  return (
    <List
      className={listClassName}
      sx={sx}
      subheader={
        (title || subTitle) && (
          <>
            <ListSubheader
              classes={{ root: classes.title }}
              component="div"
              id="nested-list-subheader"
              align={textAlign || 'left'}
              sx={{ color: textColor, backgroundColor: 'transparent' }}
            >
              {title}
              {subTitle && (
                <Typography align={textAlign} className={classes.subTitle}>
                  {subTitle}
                </Typography>
              )}
            </ListSubheader>
            {showDivider && <Divider />}
          </>
        )
      }
    >
      <Box sx={{ pl: leftMargin }}>
        {listData?.map((item, index) => {
          const newItem = Array.isArray(data) ? item : data;
          // console.log('newItem: ', newItem);
          const value =
            columns &&
            newItem &&
            (((noRef || columns[index].noRef) && columns[index].dataKey) ||
              getRef(newItem, columns[index].dataKey));
          // console.log('columns: ', columns);

          if (
            (!value && columns && newItem && !columns[index].showZero) ||
            (columns && columns[index].hide && columns[index].hide())
          )
            return null;

          const column = columns && columns[index];
          let displayValue = null;
          if (column?.isCurrency) {
            displayValue = formatDataTableValue({
              inputValue:
                noRef || column?.noRef
                  ? column?.dataKey
                  : getRef(newItem, column?.dataKey),
              units:
                noUnitsRef || column?.noUnitsRef
                  ? column.units
                  : getRef(newItem, column?.units),
              isCurrency: true,
              isNumeric: true,
              isLocalize: true,
              showDataUnits: true,
            })?.dataValue;
          } else if (column?.isDate) {
            displayValue = dateToString(stringToDate(getRef(newItem, column?.dataKey)));
          } else {
            displayValue =
              noRef || column?.noRef ? column?.dataKey : getRef(newItem, column?.dataKey);
          }

          return (
            <ListItem
              key={uuidv4()}
              className={itemClassName}
              sx={{ alignItems: 'flex-start' }}
            >
              {columns &&
                !renderItem &&
                !columns[index].renderItem &&
                (getRef(newItem, columns[index].dataKey) ||
                  columns[index].showZero ||
                  ((noRef || columns[index].noRef) && columns[index].dataKey)) &&
                columns[index] && (
                  <ListItemText
                    classes={{
                      primary: classes.primaryText,
                      secondary: classes.secondaryText,
                    }}
                    sx={{ color: textColor }}
                    primary={
                      <>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classnames(classes.primaryText, classes.inline)}
                        >
                          {columns[index]?.header}
                        </Typography>
                        {columns[index]?.tooltip && (
                          <CustomTooltip title={columns[index]?.tooltip} />
                        )}
                      </>
                    }
                    secondary={
                      listAlign === 'vertical' && (
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          sx={{ color: textColor }}
                        >
                          {(displayValue &&
                            columns[index]?.checkIfArray &&
                            Array.isArray(displayValue) &&
                            displayValue.map(x => <p>{x}</p>)) ||
                            displayValue ||
                            (columns[index].showZero && columns[index].defaultZeroValue)}
                        </Typography>
                      )
                    }
                  />
                )}
              {columns &&
                !renderItem &&
                !columns[index].renderItem &&
                listAlign === 'horizontal' &&
                columns[index] && (
                  <Typography
                    component="span"
                    variant="body2"
                    className={classnames(classes.secondaryText, classes.inline)}
                    sx={{ color: textColor, display: 'table', pl: 5, textAlign: 'right' }}
                  >
                    {(displayValue &&
                      columns[index]?.checkIfArray &&
                      Array.isArray(displayValue) &&
                      displayValue.map(x => <p>{x}</p>)) ||
                      displayValue ||
                      (columns[index].showZero && columns[index].defaultZeroValue)}
                  </Typography>
                )}
              {renderItem && renderItem(newItem)}
              {columns &&
                columns[index] &&
                columns[index].renderItem &&
                columns[index].renderItem(newItem)}
            </ListItem>
          );
        })}
      </Box>
      {notices}
    </List>
  );
};

export default ObjectList;
