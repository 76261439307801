/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import styles from './style';
import { apiCallWizard, addWizard, resetWizard } from '../../../actions/wizards';
import useMyWizard from '../wizards';
import { updateTempState, hideBackButtonState } from '../../../actions/temp';
import {
  wizardsPreviousButtonEvent,
  wizardsNextButtonEvent,
  wizardsValidationErrorEvent,
  wizardsServerErrorEvent,
} from '../../../utils/variables/events';
import { triggerClearCache } from '../../../actions/misc/clearCache';
import { useRouter, useSmall, useGTMDataLayer } from '../../../hooks';
import routes from '../../../utils/variables/routes';
import LoginAsk from './loginAsk';
// import { getStore } from '../../../store';
import { agentType, statusOk, dutchTemplate } from '../../../utils/variables';
import {
  createRelativeUrl,
  getRef,
  isValidHttpUrl,
  sourceTextFromData,
  displayErrors,
  displayWarning,
  displaySuccess,
} from '../../../utils/functions';
import {
  getAllData,
  checkComponent,
  resetWizardPayload,
  conditionalProcessBlock,
  getStoreState,
  checkFormValidation,
  getDefaultTrackingParams,
} from '../functions';
import { getAccessToken } from '../../../storage/accessToken';
import { useOnChangeApiCalls } from '../hooks';
import { getSessionStorage } from '../../../storage/sessionStorage';

const useStyles = makeStyles(styles);

let previousActiveFormIndex = 0;
let lastActiveStep;

const WizardBuilder = ({
  id,
  forms,
  wizardType,
  stepperType,
  defaultNoAuthActiveStep,
  defaultAuthActiveStep,
  action,
  lob,
  type,
  slug,
  isModal,
  disablePadding,
  clearOnWizardLoad,
  disableGridPadding,
  isAuthRequired,
  globalApiCalls,
  extraSettings,
  draftStateIdKey,
  draftStateIdLabel,
  loadSessionStorageKeys,
  askToLogin,
  askMessage,
  ...restProps // default array value to be removed
}) => {
  const classes = useStyles({ disablePadding });
  const dispatch = useDispatch();
  const router = useRouter();
  const storeGtmDataLayer = useGTMDataLayer();
  const accessToken = getAccessToken();
  const theme = useTheme();
  const { query, location } = router;
  query.stepIndex = isModal ? undefined : query?.stepIndex;

  const { stepIndex: urlStepIndex } = query;
  const reducerStepIndex = useSelector(
    state => state?.wizards?.allInitialWizardData?.stepIndex,
  );
  const stepIndex = urlStepIndex || reducerStepIndex;

  const store = useStore();
  const params = router?.query;
  Object.keys(params).forEach(
    key => (params[key] === undefined || params[key] === null) && delete params[key],
  );

  // const storeState = useSelector(state => state);

  // const allInitialWizardData = useSelector(state => state?.wizards?.allInitialWizardData);
  /*
  const [storeState, setStoreState] = useState(getStore()?.getState());
  const unsubscribe = getStore().subscribe(() => {
    setStoreState(getStore().getState());
  });
  */

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const lastEntryPoint = useSelector(state => state.wizards.lastEntryPoint);
  const reloadWizard = useSelector(state => state.temp.reloadWizard);
  // storeState?.wizards.lastEntryPoint; //
  // const cover = useSelector(state => state?.wizards?.wizard?.cover);
  const isSmall = useSmall();
  const authId = useSelector(state => state?.auth?.user?.id);
  const customerVerified =
    useSelector(state => state?.auth?.user?.customer?.customerVerified) && accessToken;
  const draftStateId = useSelector(
    state => state?.wizards?.allInitialWizardData?.[draftStateIdKey],
  );

  // // console.log('defaultStep: ', defaultStep);
  const apiCallWizardResponse = useSelector(state => state.wizards.apiCallWizardResponse);
  const isApiCallWizardSuccess = useSelector(
    state => state.wizards.isApiCallWizardSuccess,
  );
  const isGettingWizardSuccess = useSelector(
    state => state.wizards.isGettingWizardSuccess,
  );
  const isAgent =
    useSelector(
      state => state?.auth?.user?.agent?.id && state?.auth?.usertype === agentType,
    ) && accessToken;
  const usertype = useSelector(state => state.auth.usertype);
  const selectedClient = useSelector(state => state.auth?.selectedClient);
  const idVerification = useSelector(state => state.auth?.user?.idVerification) || {};

  const isDutch = theme?.template === dutchTemplate;
  // // console.log(filterForms);
  const makeDistinctSuffix = `-${lob?.lob}-${action}`;
  const appSettings = {
    isAuthenticated,
    customerVerified,
    isAgent,
    usertype,
    selectedClient,
    idVerification,
    makeDistinctSuffix,
  };

  const tempStepIndex =
    stepIndex !== null && stepIndex !== undefined
      ? parseInt(stepIndex, 10)
      : parseInt(0, 10);

  const [isRequiredError, setRequiredError] = useState(false);
  const [isValidateExpError, setValidateExpError] = useState(false);

  const getActiveForms = inputForms => {
    const getActiveFormsStoreState = getStoreState(store, params, extraSettings);
    // console.log('getActiveFormsStoreState: ', getActiveFormsStoreState);
    // // console.log(`pruthvi ${forms}`);
    let filterForms = inputForms?.filter(form => {
      form.isConditionalComponent = form?.isConditionalForm;
      form.conditionalAction = 'show';
      // console.log('form', form);
      const defaultHide = true;
      const { disable, show, required, regExpEnabled } = checkComponent(
        form,
        getActiveFormsStoreState,
        isAuthenticated,
        customerVerified,
        defaultHide,
      );
      // console.log('active: ', active);
      // console.log('storeState: ', storeState);
      // console.log('form title:', form?.title || form?.form?.title);
      // console.log('show', show);

      return show;
    });

    filterForms = filterForms?.filter(x => !x.hidden) || [];

    if (filterForms) {
      filterForms.sort((a, b) => {
        if (a?.positionNo < b?.positionNo) {
          return -1;
        }
        if (b?.positionNo < a?.positionNo) {
          return 1;
        }
        return 0;
      });
    }

    return filterForms;
  };

  const currentForms = getActiveForms(forms);

  const emptyWizardPayload = resetWizardPayload(
    currentForms,
    isAuthenticated,
    customerVerified,
    makeDistinctSuffix,
  );

  // // console.log('emptyWizardPayload: ', emptyWizardPayload);

  const storeState = getStoreState(store, params, extraSettings);

  const finalMaxForm = currentForms.reduce((acc, val) => {
    return acc?.positionNo > val?.positionNo ? acc : val;
  });
  // console.log('finalMaxForm: ', finalMaxForm);
  const filteredMaxSteps = finalMaxForm?.positionNo || 0;
  // console.log('filteredMaxSteps: ', filteredMaxSteps);

  const newStepIndex = filteredMaxSteps < tempStepIndex ? 0 : tempStepIndex;
  // console.log('tempStepIndex: ', tempStepIndex);
  // console.log('newStepIndex: ', newStepIndex);
  let currentFormIndex = currentForms?.findIndex(x => x.positionNo === newStepIndex);
  // console.log('currentFormIndex: ', currentFormIndex);
  let finalStepIndex = newStepIndex;
  if (currentFormIndex < 0) {
    finalStepIndex = 0;
    currentFormIndex = 0;
  }
  if (isModal) {
    finalStepIndex = 0;
  }
  finalStepIndex =
    (currentForms && currentForms[currentFormIndex]?.positionNo) || finalStepIndex;

  const formKey = (currentForms && currentForms[currentFormIndex]?.key) || undefined;
  // console.log('currentForms: ', currentForms);
  // console.log('currentFormIndex: ', currentFormIndex);
  // console.log('urlStepIndex: ', urlStepIndex);
  // console.log('stepIndex: ', stepIndex);
  // console.log('finalStepIndex: ', finalStepIndex);
  // console.log('formKey: ', formKey);
  if (formKey) {
    storeState.wizards.allInitialWizardData.formKey = formKey;
  }
  // console.log('storeState1111: ', storeState);
  // console.log('finalStepIndex: ', finalStepIndex);
  // const [activeStep, setActiveStep] = React.useState(formIndex);
  const [activeStep, setActiveStep] = React.useState(finalStepIndex);
  const [isGlobalCallsFinish, setGlobalCallsFinish] = useState(false);
  // console.log('activeStep11: ', activeStep);

  const makeApiCalls = useOnChangeApiCalls(storeState);

  useEffect(() => {
    if (extraSettings?.showBackButton) {
      dispatch(hideBackButtonState.hideBackButtonAction(false));
    }
  }, []);

  useEffect(() => {
    const onErrorCall = () => {
      const filteredCalls = globalApiCalls?.filter((call, index) => {
        const defaultHide = true;
        call.conditionalAction = 'show';
        const { show } = checkComponent(
          call,
          storeState,
          isAuthenticated,
          customerVerified,
          defaultHide,
        );
        // console.log('api show: ', show);
        return show;
      });
      if (filteredCalls?.some(x => x?.savedKey === 'draftClaim')) {
        const messageObj = {
          label: 'Error loading incomplete claim.',
          message: 'Sorry, we could not retrieve your incomplete claim at this time.',
        };
        const trackingKeys = currentForms?.[currentFormIndex]?.form?.trackingKeys;
        const defaultTrackingParams = getDefaultTrackingParams(storeState, trackingKeys);
        displayErrors(messageObj, classes.toastrSM, {
          timeOut: 0,
        });
        storeGtmDataLayer({
          ...defaultTrackingParams,
          event: wizardsServerErrorEvent,
          slug,
          stepIndex: activeStep,
          title: messageObj?.title,
          message: messageObj?.message,
        });
        setTimeout(() => {
          router.replace(routes?.partiallyFilledClaims?.index);
        }, 1000);
      }
    };
    const onSuccessCall = () => {
      setGlobalCallsFinish(true);
    };
    if (id && !isModal) {
      makeApiCalls({ apiCalls: globalApiCalls, onErrorCall, onSuccessCall });
      setTimeout(() => {
        dispatch(updateTempState.updateTempStateAction({ wizardLoaded: id }));
      }, 100);
    } else {
      setGlobalCallsFinish(true);
    }
  }, [id]);

  useEffect(() => {
    const auth = isAuthenticated
      ? {
          userId: authId,
        }
      : {};

    if (!isModal) {
      setActiveStep(finalStepIndex);
      const tempSessionStorageObj = {};
      if (loadSessionStorageKeys && loadSessionStorageKeys?.length > 0) {
        loadSessionStorageKeys?.map(key => {
          const storageValue =
            getSessionStorage(key?.referenceKey, { isObject: key?.isObject }) || null;
          if (key?.dontSetIfNull && !storageValue) return;
          tempSessionStorageObj[key?.key] = storageValue;
          return null;
        });
      }
      const appendData = {
        formKey,
        ...params,
        ...tempSessionStorageObj,
        initialCount: 0,
        auth,
      };
      dispatch(addWizard.addInitialWizardDataAction(appendData));
    } else {
      const appendData = { initialCount: 0, auth };
      dispatch(addWizard.addInitialWizardDataAction(appendData));
    }
  }, [slug, finalStepIndex, isAuthenticated]);

  useEffect(() => {
    previousActiveFormIndex = 0;
    if (isModal) {
      localStorage.setItem('emptyModalWizardPayload', JSON.stringify(emptyWizardPayload));
    }
  }, []);

  const runClearCache = clearCacheKey => {
    const clearCacheKeys = clearCacheKey?.split(',') || [];
    for (let i = 0; i < clearCacheKeys?.length; i++) {
      // await promiseTimeout(300, null);
      const key = clearCacheKeys[i];
      dispatch(triggerClearCache(key));
    }
  };

  const handleNext = ({ buttonAction, initialData }) => {
    // console.log("handleNextClick forms", forms);

    const handleNextStoreState =
      initialData?.state || getStoreState(store, params, extraSettings);
    if (buttonAction?.actionType === 'save' || buttonAction?.actionType === 'apiCall')
      return;
    // console.log('handleNextStoreState: ', handleNextStoreState);
    const filterForms = initialData?.filterForms || getActiveForms(forms);
    // c onsole.log('filterForms: ', filterForms);
    previousActiveFormIndex = currentFormIndex;
    // console.log('currentFormIndex: ', currentFormIndex);

    const thisFormIndex = forms?.findIndex(x => x.positionNo === finalStepIndex);
    const currentForm = forms[thisFormIndex];

    const {
      isRoutingUrlForm,
      routingUrlKey,
      routingStaticUrl,
      positionNo,
      form,
      validations,
    } = currentForm;
    // console.log('currentForm: ', currentForm);
    // console.log('validations: ', validations);
    let activeValidations = [];
    if (validations && validations?.length > 0) {
      activeValidations = validations?.filter(x => {
        const { activeCondition } = conditionalProcessBlock(
          x,
          handleNextStoreState,
          isAuthenticated,
          customerVerified,
          false,
          true,
        );
        return activeCondition;
      });
    }
    // console.log('activeValidations: ', activeValidations);
    // const { allInitialWizardData } = handleNextStoreState?.wizards || {};
    const redirectType = form?.next?.redirectType;
    const redirectUrl = form?.next?.redirectUrl;

    if (form?.next?.resetWizardData) {
      const customerId = handleNextStoreState?.auth?.user?.id || 'none';
      const clearResponse = true;
      dispatch(
        resetWizard.resetWizardDataAction(
          emptyWizardPayload,
          '',
          customerId,
          clearResponse,
        ),
      );
    }

    // // console.log('getStore(): ', getStore()?.getState());
    // const wizardData = { ...allWizardData, ...allInitialWizardData }; // , ...allWizardData
    // console.log('wizardData: ', allInitialWizardData);

    const {
      dataRequiredValid,
      dataExpValid,
      componentsWithRequireErrors,
      componentsWithExpErrors,
    } =
      initialData?.formValidations ||
      checkFormValidation(handleNextStoreState, currentForm, appSettings);
    const finalForm = filterForms.reduce((acc, val) => {
      return acc?.positionNo > val?.positionNo ? acc : val;
    });
    // console.log('finalForm: ', finalForm);
    const finalFormPosition = finalForm?.positionNo;
    // const finalFormPosition = filterForms[filterForms.length - 1]?.positionNo;
    // console.log('finalFormPosition: ', finalFormPosition);
    // console.log();console.log('finalStepIndex: ', finalStepIndex);
    // console.log('dataExpValid: ', dataExpValid);
    // console.log('dataRequiredValid: ', dataRequiredValid);
    let maxCurrentPositionNo = 0;
    const currentActiveStep =
      initialData?.activeStep !== undefined ? initialData?.activeStep : activeStep;
    if (filterForms) {
      maxCurrentPositionNo = Math.max(
        ...filterForms.map(o => (o.positionNo <= currentActiveStep ? o.positionNo : 0)),

        0,
      );
    }
    // console.log('activeStep: ', currentActiveStep);
    // console.log('maxCurrentPositionNo: ', maxCurrentPositionNo);

    const nextFormIndex =
      filterForms.findIndex(x => x.positionNo === maxCurrentPositionNo) + 1;
    // console.log('nextFormIndex: ', nextFormIndex);
    const defaultTrackingParams = getDefaultTrackingParams(
      handleNextStoreState,
      form?.trackingKeys,
    );
    const nextForms = filterForms; // filterForms.length > currentForms.length ? : currentForms;
    // console.log('redirectType', redirectType);
    // console.log('redirectUrl', redirectUrl);
    // // console.log('dataRequiredValid: ', dataRequiredValid);
    if (activeValidations && activeValidations?.length > 0) {
      const title = activeValidations?.[0]?.validationTitle || 'Error';
      const message = activeValidations?.[0]?.validationMessage || 'Error';
      const navigationDetails = activeValidations?.[0]?.navigationDetails || {};

      displayErrors(
        {
          label: title,
          message,
          navigationDetails,
        },
        classes.toastrSM,
        {
          type: activeValidations?.[0]?.validationType,
          timeOut: form?.next?.toastrTime || 0,
        },
      );

      storeGtmDataLayer({
        ...defaultTrackingParams,
        event: wizardsValidationErrorEvent,
        slug,
        stepIndex: activeStep,
        title,
        message,
      });
    } else if (!(dataRequiredValid && dataExpValid)) {
      const title = isDutch
        ? 'You cannot proceed'
        : 'You cannot proceed. The Form is Invalid!';

      const message = isDutch
        ? 'Please fill in required fields'
        : `Please recheck the form, for the invalid inputs.`;
      displayErrors(
        {
          label: title,
          message,
        },
        classes.toastrSM,
        {
          timeOut: form?.next?.toastrTime || 0,
        },
      );

      storeGtmDataLayer({
        ...defaultTrackingParams,
        event: wizardsValidationErrorEvent,
        slug,
        stepIndex: activeStep,
        title,
        message,
        requireFields: componentsWithRequireErrors?.toString(),
        expFields: componentsWithExpErrors?.toString(),
        requireFailed: !dataRequiredValid,
        expFailed: !dataExpValid,
      });
    } else if (isRoutingUrlForm) {
      if (routingStaticUrl) {
        isValidHttpUrl(routingStaticUrl)
          ? (window.location.href = routingStaticUrl)
          : router.replace(routingStaticUrl);
      } else {
        const newRoute = getRef(handleNextStoreState, routingUrlKey).toString();
        isValidHttpUrl(newRoute)
          ? (window.location.href = newRoute)
          : router.replace(newRoute);
      }
    } else if (redirectType === 'lastEntryPoint' && lastEntryPoint) {
      if (isValidHttpUrl(lastEntryPoint)) {
        window.location.href = lastEntryPoint;
      } else {
        router.replace(lastEntryPoint);
      }
    } else if (redirectType === 'goToStart') {
      router.replace(router.pathname);
    } else if (redirectType === 'redirectUrl' && redirectUrl) {
      const newRedirectUrl = sourceTextFromData(handleNextStoreState, redirectUrl);
      // console.log('newRedirectUrl: ', newRedirectUrl);
      // console.log('redirectUrl: ', redirectUrl);
      // console.log('storeState: ', storeState);
      if (isValidHttpUrl(newRedirectUrl)) {
        window.location.href = newRedirectUrl;
      } else {
        router.replace(newRedirectUrl);
      }
    } else if (redirectType === 'home') {
      // // console.log('activeStep: ', activeStep);
      router.replace(routes.index); // lob?.request?.value
    } else if (
      positionNo <= finalFormPosition &&
      nextForms[nextFormIndex] &&
      nextForms[nextFormIndex].positionNo
    ) {
      if (isModal) {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      } else {
        // setActiveStep(prevActiveStep => prevActiveStep + 1);
        const { positionNo: newPositionNo } = nextForms[nextFormIndex];
        const newParams = {
          ...params,
          stepIndex: newPositionNo, // activeStep + 1,
        };

        if (form?.next?.urlAppendKeys && form?.next?.urlAppendKeys.length > 0) {
          // console.log('urlappendKeys: ', form?.next?.urlAppendKeys);

          form?.next?.urlAppendKeys.map(key => {
            newParams[key?.key] =
              getRef(handleNextStoreState, key?.referenceKey) ||
              sourceTextFromData(handleNextStoreState, key?.staticValue);
            return null;
          });
        }

        delete newParams.slug;

        router.replace(createRelativeUrl({ queryObject: newParams }));
      }
    } else {
      const title = 'You have reached the end of the wizard!';
      const message = `You cannot proceed any further.`;
      displayWarning(
        {
          label: title,
          message,
        },
        classes.toastrSM,
      );
    }
    setRequiredError(!dataRequiredValid);
    setValidateExpError(!dataExpValid);
  };

  const handleBack = ({ buttonAction, initialData }) => {
    const handleBackStoreState =
      initialData?.state || getStoreState(store, params, extraSettings);
    if (buttonAction?.actionType === 'save' || buttonAction?.actionType === 'apiCall')
      return;

    const filterForms = initialData?.filterForms || getActiveForms(forms);

    const thisFormIndex = forms?.findIndex(x => x.positionNo === finalStepIndex);
    const { form } = forms[thisFormIndex];

    const { previous } = form;
    // if ((currentFormIndex === 0 && !isModal) || (isModal && activeStep === 0)) {
    const redirectType = previous?.redirectType;
    const redirectUrl = previous?.redirectUrl;
    const currentActiveStep =
      initialData?.activeStep !== undefined ? initialData?.activeStep : activeStep;
    if (previous?.resetWizardData) {
      const customerId = handleBackStoreState?.auth?.user?.id || 'none';
      const clearResponse = true;
      dispatch(
        resetWizard.resetWizardDataAction(
          emptyWizardPayload,
          '',
          customerId,
          clearResponse,
        ),
      );
    }

    if (redirectType === 'lastEntryPoint' && lastEntryPoint) {
      if (isValidHttpUrl(lastEntryPoint)) {
        window.location.href = lastEntryPoint;
      } else {
        router.replace(lastEntryPoint);
      }
    } else if (redirectType === 'goToStart') {
      router.replace(router.pathname);
    } else if (redirectType === 'redirectUrl' && redirectUrl) {
      const newRedirectUrl = sourceTextFromData(handleBackStoreState, redirectUrl);
      if (isValidHttpUrl(newRedirectUrl)) {
        window.location.href = newRedirectUrl;
      } else {
        router.replace(newRedirectUrl);
      }
    } else {
      // // console.log('activeStep: ', activeStep);
      setRequiredError(false);

      if ((currentFormIndex === 0 && !isModal) || (isModal && currentActiveStep === 0)) {
        router.replace(routes.index); // lob?.request?.value
      } else if (isModal) {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
      } else {
        const nextFormIndex = currentFormIndex - 1;
        const { positionNo } = filterForms[nextFormIndex];
        const newParams = {
          ...params,
          stepIndex: positionNo, // activeStep - 1,
        };

        if (form?.back?.urlAppendKeys && form?.back?.urlAppendKeys.length > 0) {
          // console.log('urlappendKeys: ', form?.next?.urlAppendKeys);

          form?.back?.urlAppendKeys.map(key => {
            newParams[key?.key] =
              getRef(handleBackStoreState, key?.referenceKey) ||
              sourceTextFromData(handleBackStoreState, key?.staticValue);
            return null;
          });
        }
        delete newParams.slug;
        router.replace(createRelativeUrl({ queryObject: newParams }));
      }
    }
    // }
    // } else {
    // setActiveStep(prevActiveStep => prevActiveStep - 1);

    // }
    // }
  };

  const handleApiNext = buttonAction => {
    // console.log('buttonAction: ', buttonAction);

    const handleApiNextStoreState = getStoreState(store, params, extraSettings);
    // console.log('handleApiNextStoreState: ', handleApiNextStoreState);

    const filterForms = getActiveForms(forms);
    // console.log('filterForms: ', filterForms);

    const currentForm = filterForms?.[currentFormIndex] || {};
    const defaultTrackingParams = getDefaultTrackingParams(
      handleApiNextStoreState,
      currentForm?.form?.trackingKeys,
    );

    const formValidations = checkFormValidation(
      handleApiNextStoreState,
      currentForm,
      appSettings,
    );
    // console.log('formValidations: ', formValidations);

    if (buttonAction?.actionType === 'save') {
      formValidations.dataRequiredValid = true;
    }
    // console.log('currentFormIndex1: ', currentFormIndex);
    // console.log('currentForm1: ', currentForm);
    const initialData = {
      activeStep,
      filterForms,
      state: handleApiNextStoreState,
      formValidations,
    };

    const {
      dataRequiredValid,
      dataExpValid,
      componentsWithRequireErrors,
      componentsWithExpErrors,
    } = formValidations || {};
    // console.log('dataExpValid: ', dataExpValid);
    // console.log('dataRequiredValid: ', dataRequiredValid);
    const apiCalls = buttonAction?.apiCalls;
    if (dataRequiredValid && dataExpValid) {
      const newStoreState = { ...handleApiNextStoreState };
      if (apiCalls && apiCalls.length > 0) {
        getAllData(
          apiCalls,
          apiCallWizard.apiCallWizardAction,
          dispatch,
          newStoreState,
          null,
          null,
          buttonAction,
        )
          .then(resp => {
            if (!resp) {
              handleNext({ buttonAction, initialData });
            } else {
              const responseTypeSuccess =
                resp && resp.length > 0
                  ? resp.every(
                      x => x?.responseType === statusOk || !x.responseType || false,
                    )
                  : false;
              if (responseTypeSuccess) {
                if (
                  buttonAction?.actionType === 'save' ||
                  buttonAction?.actionType === 'apiCall'
                ) {
                  resp?.forEach(x => {
                    const title = 'Success';
                    const message = x?.responseDesc?.message;
                    displaySuccess(
                      {
                        label: title,
                        message,
                      },
                      classes.toastrSM,
                    );
                  });
                }
                handleNext({ buttonAction, initialData });
              }
            }
          })
          .catch(e => {
            // // console.log(e);
          });
      } else {
        handleNext({ buttonAction, initialData });
      }
    } else {
      const title = 'You cannot proceed. The Form is Invalid!';
      const message = `Please recheck the form, for the invalid inputs.`;
      displayErrors(
        {
          label: title,
          message,
        },
        classes.toastrSM,
      );
      storeGtmDataLayer({
        ...defaultTrackingParams,
        event: wizardsValidationErrorEvent,
        slug,
        stepIndex: activeStep,
        title,
        message,
        requireFields: componentsWithRequireErrors?.toString(),
        expFields: componentsWithExpErrors?.toString(),
        requireFailed: !dataRequiredValid,
        expFailed: !dataExpValid,
      });
    }

    setRequiredError(!dataRequiredValid);
    setValidateExpError(!dataExpValid);
  };

  const handleApiBack = buttonAction => {
    const handleApiBackStoreState = getStoreState(store, params, extraSettings);
    const filterForms = getActiveForms(forms);
    // const { allInitialWizardData } = getStore()?.getState()?.wizards || {};
    // const wizardData = { ...allInitialWizardData, ...allWizardData };

    const initialData = {
      activeStep,
      filterForms,
      state: handleApiBackStoreState,
    };

    const apiCalls = buttonAction?.apiCalls;

    if (apiCalls && apiCalls.length > 0) {
      return getAllData(
        apiCalls,
        apiCallWizard.apiCallWizardAction,
        dispatch,
        handleApiBackStoreState,
        null,
        null,
        buttonAction,
      )
        .then(() => handleBack({ buttonAction, initialData }))
        .catch(e => {
          // // console.log(e);
        });
    }

    handleBack({ buttonAction, initialData });
  };

  const apiCalls = currentForms && currentForms[currentFormIndex]?.form?.next?.apiCalls;
  const isApiCallWizardResponseTypeOk =
    apiCallWizardResponse &&
    Object.keys(apiCallWizardResponse).length > 0 &&
    apiCalls &&
    apiCalls?.length > 0
      ? apiCalls.every(
          x => apiCallWizardResponse[x?.savedKey]?.responseType === statusOk || false,
        )
      : false;
  const isApiCallSuccess =
    isApiCallWizardSuccess &&
    Object.keys(isApiCallWizardSuccess).length > 0 &&
    apiCalls &&
    apiCalls?.length > 0
      ? apiCalls.every(x => isApiCallWizardSuccess[x?.savedKey])
      : false;

  const _isApiCallWizardPending = useSelector(
    state => state.wizards.isApiCallWizardPending,
  );

  const isApiCallWizardPending =
    _isApiCallWizardPending &&
    Object.keys(_isApiCallWizardPending).length > 0 &&
    apiCalls &&
    apiCalls?.length > 0
      ? apiCalls.map(x => _isApiCallWizardPending[x?.savedKey])
      : null;
  // // console.log('isApiCallWizardPending: ', isApiCallWizardPending);

  const isApiCallPending =
    isApiCallWizardPending && isApiCallWizardPending.length > 0
      ? isApiCallWizardPending.some(x => x)
      : false;
  // // console.log('isApiCallPending: ', isApiCallPending);

  if (
    isAuthRequired &&
    !isAuthenticated &&
    newStepIndex >= defaultAuthActiveStep &&
    (newStepIndex < defaultNoAuthActiveStep || !defaultNoAuthActiveStep)
  ) {
    return (
      <Redirect
        to={`${routes.auth.signIn}/?redirect=${location.pathname}${location.search}`}
      />
    );
  }
  const Wizard = useMyWizard(wizardType);
  const askToLoginSession = getSessionStorage('askToLogin', { isObject: true }) || null;
  const checkAsked = askToLoginSession?.[id];
  if (askToLogin && !checkAsked && !isAuthenticated) {
    return <LoginAsk id={id} askMessage={askMessage} />;
  }

  if (wizardType && wizardType.length > 0) {
    return (
      <Wizard
        forms={currentForms}
        {...restProps}
        handleBack={handleBack}
        handleApiBack={handleApiBack}
        handleApiNext={handleApiNext}
        handleNext={handleNext}
        runClearCache={runClearCache}
        activeStep={isModal ? activeStep : currentFormIndex}
        maxSteps={filteredMaxSteps}
        isRequiredError={isRequiredError}
        isValidateExpError={isValidateExpError}
        isPending={isApiCallPending}
        stepperType={stepperType}
        action={action}
        lob={lob}
        emptyWizardPayload={emptyWizardPayload}
        isModal={isModal}
        storeState={storeState}
        isCustomer={customerVerified}
        isAuthenticated={isAuthenticated}
        globalApiCalls={globalApiCalls}
        extraSettings={extraSettings}
        params={params}
        draftStateId={draftStateId}
        draftStateIdLabel={draftStateIdLabel}
        lastNextCalls={currentForms[previousActiveFormIndex]?.form?.next?.apiCalls}
        isGlobalCallsFinish={isGlobalCallsFinish}
        disablePadding={disablePadding}
        isSmall={isSmall}
        disableGridPadding={disableGridPadding}
        reloadWizard={reloadWizard}
        slug={slug}
      />
    );
  }

  return null;
};

export default WizardBuilder;

WizardBuilder.propTypes = {
  className: PropTypes.string,
  wizardType: PropTypes.oneOf(['stepper', 'collapsible']),
  stepperType: PropTypes.oneOf(['default', 'none']),
  forms: PropTypes.arrayOf(PropTypes.object).isRequired,
  lob: PropTypes.object,
  action: PropTypes.string,
};

WizardBuilder.defaultProps = {
  className: '',
  wizardType: 'stepper',
  stepperType: 'default',
  lob: null,
  action: '',
};

/*

        */
