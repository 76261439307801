// import { addAlpha } from '../utils/functions';
import { alpha } from '@mui/material/styles';

const styles = theme => ({
  button: {
    // height: '100%',
    fontWeight: 'bold !important',
  },
  importantButton: {
    borderColor: `${theme.palette.secondary.main} !important`,
    borderWidth: `1px !important`,
    borderStyle: `solid !important`,
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: `${theme.palette.secondary.contrastText} !important`,
    '&:hover': {
      color: `${theme.palette.tertiary.contrastText} !important`,
      backgroundColor: `${theme.palette.tertiary.main} !important`,
      borderColor: `${theme.palette.tertiary.main} !important`,
    },
    boxShadow: theme.effects.boxShadow,
  },
  tertiaryButton: {
    borderColor: `transparent !important`,
    borderWidth: `1px !important`,
    borderStyle: `solid !important`,
    backgroundColor: `transparent !important`,
    // backgroundColor: `${alpha(theme.palette.primary.main, 0.1)} !important`,
    color: `${theme.palette.primary.main} !important`,
    '&:hover': {
      color: `${theme.palette.primary.main} !important`,
      backgroundColor: `${alpha(theme.palette.primary.main, 0.1)} !important`,
      borderColor: `${alpha(theme.palette.primary.main, 0.1)} !important`,
    },
  },
  text: {
    borderColor: `transparent !important`,
    borderWidth: `1px !important`,
    borderStyle: `solid !important`,
    backgroundColor: `transparent !important`,
    // backgroundColor: `${alpha(theme.palette.primary.main, 0.1)} !important`,
    color: `${theme.palette.primary.main} !important`,
    '&:hover': {
      color: `${theme.palette.primary.main} !important`,
      backgroundColor: `${theme.palette.primary.main} !important`,
      borderColor: `${alpha(theme.palette.primary.main, 0.1)} !important`,
    },
  },
  success: {
    borderColor: `transparent !important`,
    borderWidth: `1px !important`,
    borderStyle: `solid !important`,
    backgroundColor: `transparent !important`,
    fontWeight: 'bold',
    // backgroundColor: `${alpha(theme.palette.primary.main, 0.1)} !important`,
    color: `${theme.palette.success.main} !important`,
    '&:hover': {
      color: `${theme.palette.success.main} !important`,
      backgroundColor: `${alpha(theme.palette.success.main, 0.1)} !important`,
      borderColor: `${alpha(theme.palette.success.main, 0.1)} !important`,
    },
  },
  warning: {
    borderColor: `transparent !important`,
    borderWidth: `1px !important`,
    borderStyle: `solid !important`,
    backgroundColor: `transparent !important`,
    fontWeight: 'bold',
    // backgroundColor: `${alpha(theme.palette.primary.main, 0.1)} !important`,
    color: `${theme.palette.warning.main} !important`,
    '&:hover': {
      color: `${theme.palette.warning.main} !important`,
      backgroundColor: `${alpha(theme.palette.warning.main, 0.1)} !important`,
      borderColor: `${alpha(theme.palette.warning.main, 0.1)} !important`,
    },
  },
  error: {
    borderColor: `transparent !important`,
    borderWidth: `1px !important`,
    borderStyle: `solid !important`,
    backgroundColor: `transparent !important`,
    fontWeight: 'bold',
    // backgroundColor: `${alpha(theme.palette.primary.main, 0.1)} !important`,
    color: `${theme.palette.error.main} !important`,
    '&:hover': {
      color: `${theme.palette.error.main} !important`,
      backgroundColor: `${alpha(theme.palette.error.main, 0.1)} !important`,
      borderColor: `${alpha(theme.palette.error.main, 0.1)} !important`,
    },
  },
  info: {
    borderColor: `transparent !important`,
    borderWidth: `1px !important`,
    borderStyle: `solid !important`,
    backgroundColor: `transparent !important`,
    fontWeight: 'bold',
    // backgroundColor: `${alpha(theme.palette.primary.main, 0.1)} !important`,
    color: `${theme.palette.info.main} !important`,
    '&:hover': {
      color: `${theme.palette.info.main} !important`,
      backgroundColor: `${alpha(theme.palette.info.main, 0.1)} !important`,
      borderColor: `${alpha(theme.palette.info.main, 0.1)} !important`,
    },
  },
  neutral: {
    borderColor: `transparent !important`,
    borderWidth: `1px !important`,
    borderStyle: `solid !important`,
    backgroundColor: `transparent !important`,
    fontWeight: 'bold',
    // backgroundColor: `${alpha(theme.palette.primary.main, 0.1)} !important`,
    color: `${theme.palette.neutral.main} !important`,
    '&:hover': {
      color: `${theme.palette.neutral.main} !important`,
      backgroundColor: `${alpha(theme.palette.neutral.main, 0.1)} !important`,
      borderColor: `${alpha(theme.palette.neutral.main, 0.1)} !important`,
    },
  },
  inactive: {
    borderColor: `transparent !important`,
    borderWidth: `1px !important`,
    borderStyle: `solid !important`,
    backgroundColor: `transparent !important`,
    fontWeight: 'bold',
    // backgroundColor: `${alpha(theme.palette.primary.main, 0.1)} !important`,
    color: `${theme.palette.inactive.main} !important`,
    '&:hover': {
      color: `${theme.palette.inactive.main} !important`,
      backgroundColor: `${alpha(theme.palette.inactive.main, 0.1)} !important`,
      borderColor: `${alpha(theme.palette.inactive.main, 0.1)} !important`,
    },
  },
  secondaryButton: {
    borderColor: `${theme.palette.primary.main} !important`,
    borderWidth: `1px !important`,
    borderStyle: `solid !important`,
    backgroundColor: `transparent !important`,
    // backgroundColor: `${alpha(theme.palette.primary.main, 0.1)} !important`,
    color: `${theme.palette.primary.main} !important`,
    '&:hover': {
      color: `${theme.palette.primary.contrastText} !important`,
      backgroundColor: `${theme.palette.primary.main} !important`,
      borderColor: `${theme.palette.primary.main} !important`,
    },
    boxShadow: theme.effects.boxShadow,
  },
  outlined: {
    borderColor: `${theme.palette.primary.main} !important`,
    borderWidth: `1px !important`,
    borderStyle: `solid !important`,
    backgroundColor: `transparent !important`,
    // backgroundColor: `${alpha(theme.palette.primary.main, 0.1)} !important`,
    color: `${theme.palette.primary.main} !important`,
    '&:hover': {
      color: `${theme.palette.primary.contrastText} !important`,
      backgroundColor: `${theme.palette.primary.main} !important`,
      borderColor: `${theme.palette.primary.main} !important`,
    },
    boxShadow: theme.effects.boxShadow,
  },
  secondary_text: {
    borderColor: `transparent !important`,
    borderWidth: `1px !important`,
    borderStyle: `solid !important`,
    backgroundColor: `transparent !important`,
    // backgroundColor: `${alpha(theme.palette.primary.main, 0.1)} !important`,
    color: `${theme.palette.secondary.main} !important`,
    '&:hover': {
      color: `${theme.palette.text.primary} !important`,
      backgroundColor: `${theme.palette.secondary.main} !important`,
      borderColor: `${alpha(theme.palette.secondary.main, 0.1)} !important`,
    },
  },
  secondary_outlined: {
    backgroundColor: `${alpha(theme.palette.secondary.main, 0.1)} !important`,
    color: `${theme.palette.secondary.main} !important`,
    borderColor: `${theme.palette.secondary.main} !important`,
    '&:hover': {
      color: `${theme.palette.secondary.contrastText} !important`,
      backgroundColor: `${theme.palette.secondary.main} !important`,
      borderColor: `${theme.palette.secondary.main} !important`,
    },
  },
  secondary_contained: {
    borderColor: `${theme.palette.secondary.main} !important`,
    borderWidth: `1px !important`,
    borderStyle: `solid !important`,
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: `${theme.palette.secondary.contrastText} !important`,
    '&:hover': {
      color: `${theme.palette.tertiary.contrastText} !important`,
      backgroundColor: `${theme.palette.tertiary.main} !important`,
      borderColor: `${theme.palette.tertiary.main} !important`,
    },
    boxShadow: theme.effects.boxShadow,
  },
  primary_text: {
    borderColor: `transparent !important`,
    borderWidth: `1px !important`,
    borderStyle: `solid !important`,
    backgroundColor: `transparent !important`,
    // backgroundColor: `${alpha(theme.palette.primary.main, 0.1)} !important`,
    color: `${theme.palette.primary.main} !important`,
    '&:hover': {
      color: `${theme.palette.primary.contrastText} !important`,
      backgroundColor: `${theme.palette.primary.main} !important`,
      borderColor: `${alpha(theme.palette.primary.main, 0.1)} !important`,
    },
  },
  primary_outlined: {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.1)} !important`,
    color: `${theme.palette.primary.main} !important`,
    borderColor: `${theme.palette.primary.main} !important`,
    '&:hover': {
      color: `${theme.palette.primary.contrastText} !important`,
      backgroundColor: `${theme.palette.primary.main} !important`,
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  primary_contained: {
    borderColor: `${theme.palette.primary.main} !important`,
    borderWidth: `1px !important`,
    borderStyle: `solid !important`,
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.contrastText} !important`,
    '&:hover': {
      color: `${theme.palette.tertiary.contrastText} !important`,
      backgroundColor: `${theme.palette.tertiary.main} !important`,
      borderColor: `${theme.palette.tertiary.main} !important`,
    },
    boxShadow: theme.effects.boxShadow,
  },
  contained: {
    borderColor: `${theme.palette.primary.main} !important`,
    borderWidth: `1px !important`,
    borderStyle: `solid !important`,
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.contrastText} !important`,
    '&:hover': {
      color: `${theme.palette.tertiary.contrastText} !important`,
      backgroundColor: `${theme.palette.tertiary.main} !important`,
      borderColor: `${theme.palette.tertiary.main} !important`,
    },
    boxShadow: theme.effects.boxShadow,
  },
  primaryButton: {
    borderColor: `${theme.palette.primary.main} !important`,
    borderWidth: `1px !important`,
    borderStyle: `solid !important`,
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.contrastText} !important`,
    '&:hover': {
      color: `${theme.palette.tertiary.contrastText} !important`,
      backgroundColor: `${theme.palette.tertiary.main} !important`,
      borderColor: `${theme.palette.tertiary.main} !important`,
    },
    boxShadow: theme.effects.boxShadow,
  },
  primaryButtonInverted: {
    backgroundColor: `${theme.palette.primary.contrastText} !important`,
    color: `${theme.palette.primary.main} !important`,
    '&:hover': {
      color: `${theme.palette.primary.contrastText} !important`,
      backgroundColor: `${theme.palette.primary.main} !important`,
      borderColor: `${theme.palette.primary.contrastText} !important`,
    },
    boxShadow: theme.effects.boxShadow,
  },
  disabled: {
    backgroundColor: `${theme.palette.text.disabled} !important`,
    color: `${theme.palette.text.bright} !important`,
  },
  invertContained: {
    '&:hover': {
      color: `${theme.palette.primary.main} !important`,
      backgroundColor: `${theme.palette.text.bright} !important`,
      borderColor: `${theme.palette.primary.main} !important`,
    },
    backgroundColor: `${theme.palette.background.chip} !important`,
    color: `${theme.palette.text.bright} !important`,
    borderColor: `${theme.palette.text.bright} !important`,
    borderWidth: `2px !important`,
    borderStyle: `solid !important`,
  },
  font: {
    fontSize: theme.typography.caption.fontSize,
  },
  icon: {
    fontSize: theme.typography.h4.fontSize,
  },
  iconInvert: {
    backgroundColor: `${alpha(theme.palette.text.bright, 0.1)} !important`,
    color: `${theme.palette.text.bright} !important`,
    '&:hover': {
      color: `${theme.palette.primary.contrastText} !important`,
      backgroundColor: `${theme.palette.primary.main} !important`,
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  link: {
    textDecoration: 'none !important',
    color: `${
      theme.palette.mode === 'dark'
        ? theme.palette.secondary.main
        : theme.palette.primary.main
    } !important`,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline !important',
      color: `${theme.palette.tertiary.main} !important`,
    },
  },
  menuItem: {
    borderColor: `transparent !important`,
    borderWidth: `1px !important`,
    borderStyle: `solid !important`,
    backgroundColor: `transparent !important`,
    color: `${theme.palette.primary.main} !important`,
    width: `10rem`,
  },

  splitButtonContainer: {
    display: 'flex',
    justifyContent: 'spaceBetween',
  },

  splitButtonText: {
    flex: 9,
  },
  successOutlined: {
    borderColor: `${theme.palette.success.main} !important`,
    borderWidth: `1px !important`,
    borderStyle: `solid !important`,
    backgroundColor: `transparent !important`,
    fontWeight: 'bold',
    // backgroundColor: `${alpha(theme.palette.primary.main, 0.1)} !important`,
    color: `${theme.palette.success.main} !important`,
    '&:hover': {
      color: `${theme.palette.success.main} !important`,
      backgroundColor: `${alpha(theme.palette.success.main, 0.1)} !important`,
      borderColor: `${alpha(theme.palette.success.main, 0.1)} !important`,
    },
  },
  warningOutlined: {
    borderColor: `${theme.palette.warning.main} !important`,
    borderWidth: `1px !important`,
    borderStyle: `solid !important`,
    backgroundColor: `transparent !important`,
    fontWeight: 'bold',
    // backgroundColor: `${alpha(theme.palette.primary.main, 0.1)} !important`,
    color: `${theme.palette.warning.main} !important`,
    '&:hover': {
      color: `${theme.palette.warning.main} !important`,
      backgroundColor: `${alpha(theme.palette.warning.main, 0.1)} !important`,
      borderColor: `${alpha(theme.palette.warning.main, 0.1)} !important`,
    },
  },
  errorOutlined: {
    borderColor: `${theme.palette.error.main} !important`,
    borderWidth: `1px !important`,
    borderStyle: `solid !important`,
    backgroundColor: `transparent !important`,
    fontWeight: 'bold',
    // backgroundColor: `${alpha(theme.palette.primary.main, 0.1)} !important`,
    color: `${theme.palette.error.main} !important`,
    '&:hover': {
      color: `${theme.palette.error.main} !important`,
      backgroundColor: `${alpha(theme.palette.error.main, 0.1)} !important`,
      borderColor: `${alpha(theme.palette.error.main, 0.1)} !important`,
    },
  },
  infoOutlined: {
    borderColor: `${theme.palette.info.main} !important`,
    borderWidth: `1px !important`,
    borderStyle: `solid !important`,
    backgroundColor: `transparent !important`,
    fontWeight: 'bold',
    // backgroundColor: `${alpha(theme.palette.primary.main, 0.1)} !important`,
    color: `${theme.palette.info.main} !important`,
    '&:hover': {
      color: `${theme.palette.info.main} !important`,
      backgroundColor: `${alpha(theme.palette.info.main, 0.1)} !important`,
      borderColor: `${alpha(theme.palette.info.main, 0.1)} !important`,
    },
  },
});
export default styles;
