// import { api } from '../../../utils/variables';
import { referralVerificationApi } from '../../../apis/auth';
import { updateTempState } from '../../temp';

export function referralVerificationAction(params) {
  return (dispatch, getState) => {
    const state = getState();
    return referralVerificationApi
      .referralVerification(state, params)
      .then(data => {
        dispatch(
          updateTempState.updateTempStateAction({
            referralId: data?.payload?.referralId,
          }),
        );
        return data;
      })
      .catch(error => {
        throw error;
      });
  };
}

export default {
  referralVerificationAction,
};
