import {
  Card,
  CardContent,
  CardActions,
  Grid,
  Icon,
  Box,
  Link,
  Typography,
  Button,
  useMediaQuery,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';
import React from 'react';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import styles from './style';
import { LobIcon } from '../../misc';
import { useSmall } from '../../../hooks';
import { Spinner } from '../../common';
import { getRef } from '../../../utils/functions';

function FatumCategoryCard(props) {
  const { product, isAction, loading, iconGridSize } = props;
  const { materialIcon, icon, customIcon, title, subtitle, onClick, href, lob } = product;

  const isSmall = useSmall();
  const theme = useTheme();
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const lobCategories = useSelector(state => state.locations?.lobCategories);
  if (lob) {
    const lobObj = lobCategories.find(item => item.lob === lob);
    const { color } = lobObj || {};
    product.background = getRef(theme.palette, color);
    product.iconBackground = getRef(theme.palette, color);
  }

  return (
    <Card
      className={classnames(classes.cardStyle)}
      variant="outlined"
      sx={{ backgroundColor: product.background }}
    >
      <Button component={RouterLink} to={href} onClick={onClick}>
        <Grid container className={classnames(classes.gridStyle)} spacing={1}>
          <Grid item xs={iconGridSize} md={iconGridSize}>
            <LobIcon
              customIcon={customIcon}
              lob={lob}
              materialIcon={materialIcon}
              icon={icon}
              className={classes.iconContainer}
            />
          </Grid>
          <Grid item xs={12} align="left">
            {title && (
              <Typography className={classes.cardTitleStyles} align="left">
                {title}
              </Typography>
            )}
          </Grid>
          <Grid
            xs={12}
            md={isAction ? 8 : 12}
            item
            className={classes.cardContentStyles}
            align="left"
          >
            {subtitle && (
              <>
                {loading ? (
                  <Spinner />
                ) : (
                  <Typography className={classes.cardContentStyles}>
                    {subtitle}
                  </Typography>
                )}
              </>
            )}
          </Grid>
        </Grid>
        <CardActions className={classnames(classes.cardActionStyle)}>
          <Button className={classnames(classes.buttonStyle)} variant="contained">
            <KeyboardDoubleArrowRightIcon
              color={theme.palette.primary.main}
              sx={{ fontSize: '2rem' }}
            />
          </Button>
        </CardActions>
      </Button>
    </Card>
  );
}

export default FatumCategoryCard;
