const darkTheme = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#ffbc63',
      contrastText: '#000000',
    },
    secondary: {
      main: '#FFFFFF',
      contrastText: '#000000',
    },
    tertiary: {
      main: '#89aac9',
      contrastText: '#000000',
    },
    quaternary: {
      main: '#d894af',
      contrastText: '#000000',
    },
    quinary: {
      main: '#1792e0',
      contrastText: '#000000',
    },
    senary: {
      main: '#002dfc',
      contrastText: '#000000',
    },
    septenary: {
      main: '#1593de',
      contrastText: '#000000',
    },
    octonary: {
      main: '#0767e2',
      contrastText: '#000000',
    },
    dark: {
      main: '#b0ab98',
      contrastText: '#000000',
    },
    background: {
      default: '#202020', // '#282C35', // '#0d0c0a',
      paper: '#232323', // '#282C35', #262626
      grid: 'rgba(255, 255, 255, 0.23)', // '#335C67', // '#332A86', // '#124E78', // '#282C35',
      banner: '#404041', // '#335C67', // grey[700],
      text: '#0b0b0b',
      main: '#0F0F0F',
      chip: '#b0ab98', // 4F5467
      transparent: '#00000022',
      contentBox: '#53555b',
      chatbotDefault: 'rgba(0, 0, 0, 0.38)',
    },
    text: {
      primary: '#E9EBEF',
      secondary: '#d8dae3',
      disabled: 'rgba(255,255,255,0.70)',
      label: '#d8dae3',
      main: '#E9EBEF',
      dark: '#cdced4',
      bright: '#303030',
      chatbotPrimary: 'rgba(0, 0, 0, 0.87)',
      chatbotSecondary: 'rgba(0, 0, 0, 0.54)',
    },
    inactive: {
      veryLight: '#E9EBEF',
      light: '#CED1D6',
      main: '#AEB1BD',
      dark: '#5C656D',
      veryDark: '#4F5467',
    },
    error: {
      light: '#f44336', // '#ad0505', // '#f44336',
      main: '#AA0000',
      background: 'transparent', // '#EEAABB',
    },
    warning: {
      light: '#FFDDCC', // '#FFD200', // '#F7CD86',
      main: '#DD3311',
      background: 'transparent', //
    },
    success: {
      light: '#00C5A1', // '#332A86', // '#2E30C1', // '#35AF28', // '#B1FC9C',
      main: '#00BB55',
      background: 'transparent', // '#BBEEDD',
    },
    info: {
      light: '#0089D6',
      main: '#006CBB',
      background: 'transparent', // '#BBCCEE',
    },
    neutral: {
      light: '#FEFEEF',
      main: '#CED1D6',
      background: 'transparent', // '#E9EBEF',
    },
  },
  shape: {
    borderRadius: 6,
    borderWidth: 1,
  },
  typography: {
    fontSize: 14,
    fontFamily: ['Interval Pro Regular', ' sans-serif'].join(','),
  },
  grid: {
    fontFamily: ['Interval Pro Regular', ' sans-serif'].join(','),
  },
  effects: {
    boxShadow: '0 1px 4px #0000001f !important',
    textShadow: '0 1px 1px #0000001f',
    transition: 'all .25s cubic-bezier(0.4, 0, 0.2, 1) !important',
  },
};

export default darkTheme;
