export const serverModalErrorEvent = 'server_error';
export const loginEvent = 'login';
export const checkoutEvent = 'checkout';
export const checkoutErrorEvent = 'checkout_error';
export const renewalStartStep1Event = 'renewal_start_step1';
export const renewalStartStep1ErrorEvent = 'renewal_start_step1_error';
export const renewalReviewedPolicyStep2Event = 'renewal_reviewed_policy_step2';
export const renewalDeliveryOptionStep3Event = 'renewal_delivery_option_step3';
export const renewalAgentNotifyClientStep4Event = 'renewal_agent_notify_client_step4';
export const renewalAddToCartStep4Event = 'renewal_add_to_cart_step4';
export const renewalPayOfflineStep4Event = 'renewal_pay_offline_step4';
export const premiumAddToCartEvent = 'premium_add_to_cart';
export const premiumAddToCartErrorEvent = 'premium_add_to_cart_error';
export const facPaymentStartEvent = 'fac_payment_start';
export const facPaymentStartErrorEvent = 'fac_payment_start_error';
export const facPaymentDeclineEvent = 'fac_payment_decline';
export const facPaymentCompleteEvent = 'fac_payment_complete';
export const signUpEvent = 'sign_up';
export const signUpMatchedEvent = 'sign_up_matched';
export const viewPolicyDetailsEvent = 'view_policy_details';
export const viewHealthCardEvent = 'view_health_card';
export const wizardsPreviousButtonEvent = 'wizards_previous';
export const wizardsNextButtonEvent = 'wizards_next';
export const wizardsSaveButtonEvent = 'wizards_save';
export const wizardsFormEvent = 'wizards_form';
export const wizardsComponentEvent = 'wizards_component';
export const wizardsServerErrorEvent = 'wizards_server_error';
export const wizardsValidationErrorEvent = 'wizards_validation_error';
export const surveyLoadEvent = 'survey_loaded';
export const removeFromCartEvent = 'remove_from_cart';
export const updateCartQuantityEvent = 'update_cart';
export const newRequestButtonClickEvent = 'new_request_button_click';
export const wizardCardButtonClickEvent = 'wizard_card_button_click';
export const renewalAmendPolicyEvent = 'renewal_amend_policy';
export const menuNavigationEvent = 'menu_navigation';
export const healthRequestConsultationEvent = 'health_request_consultation';
export const downloadHealthCardEvent = 'download_health_card';
export const viewInvestmentHoldingDetailsEvent = 'view_investment_holding_details';
export const openChatBotEvent = 'open_chatbot';
export const portfolioLoadedEvent = 'portfolio_loaded';
