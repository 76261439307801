import {
  DELETE_QUOTES_API_REQUEST,
  DELETE_QUOTES_API_SUCCESS,
  DELETE_QUOTES_API_ERROR,
  GET_NEXT_QUOTES_SUCCESS,
  UPDATE_QUOTES_TOTAL,
  UPDATE_QUOTES_STATUS,
} from '../../../constants/quoteActionNames';
import { agentType } from '../../../utils/variables';
import { createRelativeUrl } from '../../../utils/functions';
import { checkCacheValid } from '../../../utils/functions/cache';
import { getQuotesUrl } from '../../../apis/quotes/getQuotesApi';
import { deleteQuotesApi } from '../../../apis/quotes';
import { getPayloadResponse } from '../../../reducers/quotes';

function deleteQuotesRequest() {
  return {
    type: DELETE_QUOTES_API_REQUEST,
    payload: true,
  };
}

function deleteQuotesSuccess(quotes, params) {
  return {
    type: DELETE_QUOTES_API_SUCCESS,
    payload: true,
    // payload: quotes,
    // params,
    // lobTypes,
    // cacheKey,
  };
}

function deleteQuotesTotal(params, cacheKey, lobTypes) {
  return {
    type: UPDATE_QUOTES_TOTAL,
    payload: [],
    params,
    lobTypes,
    cacheKey,
  };
}

function deleteQuotesError(err) {
  return {
    type: DELETE_QUOTES_API_ERROR,
    payload: err,
  };
}

export function deleteQuotesAction(params) {
  // const response = getPayloadResponse();
  return (dispatch, getState) => {
    const state = getState();
    // params.alpha2 = state?.locations?.yourLocation?.alpha2;
    // const cacheKey = createRelativeUrl({
    //   path: getQuotesUrl,
    //   queryObject: params,
    // });

    // const usertype = state?.auth?.usertype;
    // const lobTypes = state?.locations?.lobCategories;
    // let filteredLobCategories = [];
    // if (usertype === agentType) {
    //   filteredLobCategories = lobTypes.filter(x => x.isAvailableForAgentClaims);
    // } else {
    //   filteredLobCategories = lobTypes.filter(x => x.isAvailableForCustomerClaims);
    // }

    // const isCacheValid = checkCacheValid(getState, 'quotes', cacheKey);
    // if (isCacheValid) {
    //   // dispatch(getClaimsSuccess(null, params, cacheKey, filteredLobCategories));
    //   dispatch(updateQuotesTotal(params, cacheKey, filteredLobCategories));
    //   return Promise.resolve(null);
    // }

    dispatch(deleteQuotesRequest());
    return deleteQuotesApi
      .deleteQuotes(state, params)
      .then(data => {
        dispatch(deleteQuotesSuccess(data, params));
        return data;
      })
      .catch(error => {
        dispatch(deleteQuotesError(error, params));
        throw error;
        // return error;
      });
  };
}

export default { deleteQuotesAction };
