import { CountryRegionData } from 'react-country-region-selector';
import { DEFAULT_KEY, generateCacheTTL } from '../../utils/functions/cache';
import {
  GET_PROFILE_API_REQUEST,
  GET_PROFILE_API_SUCCESS,
  GET_PROFILE_API_ERROR,
  UPDATE_PROFILE_API_REQUEST,
  UPDATE_PROFILE_API_SUCCESS,
  UPDATE_PROFILE_API_ERROR,
  UPDATE_PROFILE_CRM_API_REQUEST,
  UPDATE_PROFILE_CRM_API_SUCCESS,
  UPDATE_PROFILE_CRM_API_ERROR,
  VALIDATE_PROFILE_API_REQUEST,
  VALIDATE_PROFILE_API_ERROR,
  VALIDATE_PROFILE_API_SUCCESS,
  VERIFY_CONTACT_API_REQUEST,
  VERIFY_CONTACT_API_ERROR,
  VERIFY_CONTACT_API_SUCCESS,
  UPLOAD_PROFILE_PASSPORT_API_REQUEST,
  UPLOAD_PROFILE_PASSPORT_API_SUCCESS,
  UPLOAD_PROFILE_PASSPORT_API_ERROR,
  UPLOAD_PROFILE_DRIVERS_PERMIT_API_REQUEST,
  UPLOAD_PROFILE_DRIVERS_PERMIT_API_SUCCESS,
  UPLOAD_PROFILE_DRIVERS_PERMIT_API_ERROR,
  UPLOAD_PROFILE_ID_CARD_API_REQUEST,
  UPLOAD_PROFILE_ID_CARD_API_SUCCESS,
  UPLOAD_PROFILE_ID_CARD_API_ERROR,
  RESET_VERIFY_CONTACT_SUCCESS,
  RESET_VALIDATE_PROFILE_SUCCESS,
  GET_TRUSTED_DEVICES_API_REQUEST,
  GET_TRUSTED_DEVICES_API_SUCCESS,
  GET_TRUSTED_DEVICES_API_ERROR,
  DELETE_TRUSTED_DEVICE_API_REQUEST,
  DELETE_TRUSTED_DEVICE_API_SUCCESS,
  DELETE_TRUSTED_DEVICE_API_ERROR,
  GET_DOCUMENTS_API_REQUEST,
  GET_DOCUMENTS_API_SUCCESS,
  GET_DOCUMENTS_API_ERROR,
  DOWNLOAD_DOCUMENT_API_REQUEST,
  DOWNLOAD_DOCUMENT_API_SUCCESS,
  DOWNLOAD_DOCUMENT_API_ERROR,
} from '../../constants/profileActionNames';
import {
  dateToString,
  isoStringToDate,
  createStringArray,
  renameKeys,
  removeItemOnce,
  formatTitleToDropDownOptions,
  formatTitle,
} from '../../utils/functions';
import { statusOk } from '../../utils/variables';
// import initialData from './initialData';

const createMobile = arr => {
  if (!Array.isArray(arr)) return null;

  const values = {};
  for (let i = 0; i < arr.length; i++) {
    const fillSource = arr[i]?.type || '';
    const separateSource = fillSource?.toLowerCase();
    values[separateSource] = arr[i]?.value;
  }
  return values;
};

const createSourceIdsArr = obj => {
  const arr = [];
  if (obj?.plexis) {
    arr.push({ system: 'PLE', id: obj?.plexis });
  }
  if (obj?.webstar) {
    arr.push({ system: 'WEB', id: obj?.webstar });
  }
  if (obj['4series']) {
    arr.push({ system: '4SE', id: obj['4series'] });
  }
  if (obj?.ingenium) {
    arr.push({ system: 'ING', id: obj?.ingenium });
  }
  return arr;
};

const createAddress = arr => {
  if (!Array.isArray(arr)) return null;
  const values = {};
  for (let i = 0; i < arr.length; i++) {
    const fillSource = arr[i]?.type || 'res';
    const separateSource = fillSource?.toLowerCase();
    values[separateSource] = arr[i];
  }
  return values;
  /* if (!Array.isArray(profileData)) return null;
  return profileData.map((object, index) => {
    return {
      id: index,
      type: object?.type,
      addressLine1: object?.street || ' ',
      addressLine2: object?.street1 || ' ',
      city: object?.city || ' ',
      state: object?.state || ' ',
      country: object?.country || ' ',
      postalZipCode: object?.zipCode || ' ',
      isPrimaryAddress: false,
    };
  }); */
};

const createId = arr => {
  if (!Array.isArray(arr)) return null;
  const values = {};
  for (let i = 0; i < arr.length; i++) {
    const fillSource = arr[i]?.type || '';
    const separateSource = fillSource?.toLowerCase();
    values[separateSource] = arr[i]?.value;
  }
  return values;
  /* if (!Array.isArray(profileData)) return null;
  return profileData.map((object, index) => {
    return {
      id: index,
      type: object?.type,
      number: object?.value,
      expiration: 'Need Data',
    };
  });
  */
};

const createSocial = arr => {
  if (!Array.isArray(arr)) return null;
  const values = {};
  for (let i = 0; i < arr.length; i++) {
    const fillSource = arr[i]?.type || '';
    const separateSource = fillSource?.toLowerCase();
    values[separateSource] = arr[i];
  }
  return values;
  /*
  if (!Array.isArray(profileData)) return null;
  return profileData.map((object, index) => {
    return {
      id: index,
      type: 'Need Data',
      linked: 'Need Data',
    };
  });
  */
};
const createSourceIds = sourceIds => {
  // console.log('sourceIds: ', sourceIds);
  const idValues = {};
  const arr = sourceIds || [];
  // const alphas = action?.params?.alphas || [];
  // // console.log('alphas: ', alphas);
  for (let i = 0; i < arr.length; i++) {
    const fillSource = arr[i]?.source || '';
    const prefixes = ['TT', 'JAM', 'CUR', 'BB'];
    let separateSource = fillSource;
    prefixes?.forEach(x => {
      if (fillSource?.startsWith(x)) {
        separateSource = fillSource?.replace(x, '')?.toLowerCase();
      }
    });
    // const reduceSource = separateSource?.reduce((acc, index) => {
    // return acc?.toLowerCase()?.replace(index, '');
    // }, fillSource);
    idValues[separateSource] = arr[i]?.id;
    idValues[fillSource] = arr[i]?.id;
    // // console.log('reduceSource: ', separateSource);
  }
  return idValues;
};

export const createPayload = profileData => {
  const sourceIds = createSourceIds(profileData?.sourceIds);
  const profile = {
    ...profileData,
    clientId: profileData?.masterId || profileData?.c360Id, // profileData?._id,
    // profileCompleted: profileData?.profileCompleted,
    fullName: `${profileData?.firstName} ${profileData?.lastName}`,
    title: formatTitleToDropDownOptions(profileData?.title),
    firstName: profileData?.firstName,
    lastName: profileData?.lastName,
    gender: profileData?.gender,
    dateOfBirth: dateToString(isoStringToDate(profileData?.dob)),
    // maritalStatus: profileData?.maritalStatus,
    phones: createMobile(profileData?.phones),
    primaryEmail: profileData?.primaryEmail,
    primaryPhone: profileData?.primaryPhone,
    addresses: createAddress(profileData?.addresses),
    ids: createId(profileData?.ids),
    originalSourceIds: profileData?.sourceIds,
    sourceIds,
    sourceArrayIds: createSourceIdsArr(sourceIds),
    // sourceArrayIds: createStringArray(profileData?.sourceIds, 'id'),
    // socialSection: createSocial(profileData?.social),
    // mailingSameAsResidential: profileData?.mailingSameAsResidential,
  };

  let residentialAddress = '';
  let fullRegion = null;
  let fullCountry = null;

  if (profile?.addresses?.res?.addressLine1) {
    residentialAddress += profile?.addresses?.res?.addressLine1;
  }
  if (profile?.addresses?.res?.addressLine2) {
    if (residentialAddress.length > 0) {
      residentialAddress += ', ';
    }
    residentialAddress += profile?.addresses?.res?.addressLine2;
  }
  if (profile?.addresses?.res?.addressLine3) {
    if (residentialAddress.length > 0) {
      residentialAddress += ', ';
    }
    residentialAddress += profile?.addresses?.res?.addressLine3;
  }
  /*
  if (profile?.addresses?.res?.city) {
    if (residentialAddress.length > 0) {
      residentialAddress += ', ';
    }
    residentialAddress += profile?.addresses?.res?.city;
  }
  */

  if (profile?.addresses?.res?.country) {
    const countryCode = profile?.addresses?.res?.country;
    const fullCountryArray = CountryRegionData?.find(
      x => x[1] === countryCode?.toUpperCase(),
    );
    fullCountry = fullCountryArray && fullCountryArray[0] ? fullCountryArray[0] : null;
  }

  if (profile?.addresses?.res?.region) {
    const countryCode = profile?.addresses?.res?.country;
    const regionFullCode = profile?.addresses?.res?.region;
    const fullCountryArray = CountryRegionData?.find(
      x => x[1] === countryCode?.toUpperCase(),
    );
    const allRegions =
      fullCountryArray && fullCountryArray[2] ? fullCountryArray[2] : null;
    const splitRegions = allRegions?.split('|');
    const regionResult = splitRegions?.forEach(x => {
      const [regionName, regionCode] = x?.split('~');
      if (regionCode && regionCode?.toUpperCase() === regionFullCode?.toUpperCase()) {
        fullRegion = regionName;
      }
    });
  }

  let fullCity = '';
  let fullResidentialAddress = residentialAddress;
  if (profile?.addresses?.res?.city) {
    if (fullResidentialAddress.length > 0) {
      fullResidentialAddress += ', ';
    }
    fullResidentialAddress += profile?.addresses?.res?.city;
    fullCity = profile?.addresses?.res?.city;
  }

  if (fullRegion && fullRegion.length > 0) {
    if (fullResidentialAddress.length > 0) {
      fullResidentialAddress += ', ';
    }
    fullResidentialAddress += fullRegion;
  }
  if (fullCountry && fullCountry.length > 0) {
    if (fullResidentialAddress.length > 0) {
      fullResidentialAddress += ', ';
    }
    fullResidentialAddress += fullCountry;
  }
  profile.fullCountry = fullCountry;
  profile.fullCity = fullCity;
  profile.fullRegion = fullRegion;
  profile.residentialAddress = residentialAddress;
  profile.fullResidentialAddress = fullResidentialAddress;

  return profile;
};

export const initialState = {
  isPending: false,
  isError: false,
  isSuccess: false,
  response: null,
  error: null,
  isUpdateSuccess: false,
  isValidateError: false,
  isValidateSuccess: false,
  isValidatePending: false,
  validateResponse: null,
  validateError: null,
  isVerifySuccess: false,
  isVerifyError: false,
  isVerifyPending: false,
  verifyResponse: null,
  verifyError: null,
  addressFile: null,
  isUploadPending: false,
  isUploadSuccess: false,
  isUploadError: false,
  uploadResponse: null,
  uploadError: null,
  isTrustedDevicesRequest: false,
  isTrustedDevicesSuccess: false,
  isTrustedDevicesError: false,
  isDeleteTrustedDeviceRequest: false,
  isDeleteTrustedDeviceSuccess: false,
  isDeleteTrustedDeviceError: false,
  trustedDevices: [],
  // profile: null,
  profile: null,
  profileDataForVarification: null,
  agentProfile: null,
  isDocumentsPending: false,
  isDocumentsError: false,
  isDocumentsSuccess: false,
  documents: [],
  isDownloadDocumentPending: false,
  isDownloadDocumentError: false,
  isDownloadDocumentSuccess: false,
  downloadDocument: null,
  [DEFAULT_KEY]: null,
};

function profileReducer(_state = initialState, action) {
  // Reseting the state
  const state = {
    ..._state,
    isVerifySuccess: false,
    isValidateSuccess: false,
    isSuccess: false,
  };
  switch (action.type) {
    case GET_PROFILE_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };
    case GET_PROFILE_API_SUCCESS: {
      const response = action.payload;
      const cacheKey = action?.cacheKey;
      const profileData = response?.payload?.profile || state?.profile;
      const profile = createPayload(profileData) || state?.profile;
      const agentProfile = response?.payload?.agent || state?.agentProfile;
      const isError = response?.responseType !== statusOk;
      return {
        ...state,
        isPending: false,
        isError,
        isSuccess: true,
        isUpdateSuccess: false,
        response,
        // profile,
        profile,
        agentProfile,
        [DEFAULT_KEY]: {
          ...(state[DEFAULT_KEY] || {}),
          [cacheKey]: generateCacheTTL(),
        },
      };
    }
    case GET_PROFILE_API_ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        // error: action.payload,
      };
    case UPDATE_PROFILE_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };
    case UPDATE_PROFILE_CRM_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };
    case UPDATE_PROFILE_CRM_API_SUCCESS:
      return {
        ...state,
        isPending: false,
        isError: false,
        isSuccess: true,
        // use this key to do the redirect
        isUpdateSuccess: true,
        profileDataForVarification: true,
      };
    case UPDATE_PROFILE_API_SUCCESS: {
      const response = action.payload;
      const profileData = createPayload(response) || state?.profile;
      return {
        ...state,
        profile: profileData,
        isPending: false,
        isError: false,
        isSuccess: true,
        // use this key to do the redirect
        isUpdateSuccess: true,
      };
    }

    case UPDATE_PROFILE_API_ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
      };

    case UPDATE_PROFILE_CRM_API_ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
      };

    case VALIDATE_PROFILE_API_REQUEST:
      return {
        ...state,
        isValidatePending: true,
        isValidateError: false,
        isValidateSuccess: false,
      };

    case VALIDATE_PROFILE_API_SUCCESS: {
      const response = action.payload;

      return {
        ...state,
        validateResponse: response,
        isValidatePending: false,
        isValidateError: false,
        isValidateSuccess: true,
      };
    }

    case VALIDATE_PROFILE_API_ERROR: {
      const response = action.payload;
      return {
        ...state,
        isValidatePending: false,
        isValidateError: true,
        isValidateSuccess: false,
        validateError: response,
      };
    }

    case VERIFY_CONTACT_API_REQUEST:
      return {
        ...state,
        isVerifyPending: true,
        isVerifyError: false,
        isVerifySuccess: false,
      };

    case VERIFY_CONTACT_API_SUCCESS: {
      const response = action.payload;

      return {
        ...state,
        // profile: response,
        isVerifyPending: false,
        isVerifyError: false,
        isVerifySuccess: true,
        verifyResponse: response,
      };
    }

    case VERIFY_CONTACT_API_ERROR: {
      const response = action.payload;
      return {
        ...state,
        isVerifyPending: false,
        isVerifyError: true,
        isVerifySuccess: false,
        verifyError: response,
      };
    }

    case UPLOAD_PROFILE_PASSPORT_API_REQUEST:
      return {
        ...state,
        isUploadPending: true,
        isUploadError: false,
        isUploadSuccess: false,
      };

    case UPLOAD_PROFILE_PASSPORT_API_SUCCESS: {
      const response = action.payload;

      return {
        ...state,
        // profile: response,
        isUploadPending: false,
        isUploadError: false,
        isUploadSuccess: true,
        uploadResponse: response,
      };
    }

    case UPLOAD_PROFILE_PASSPORT_API_ERROR: {
      const response = action.payload;
      return {
        ...state,
        isUploadPending: false,
        isUploadError: true,
        isUploadSuccess: false,
        uploadError: response,
      };
    }

    case UPLOAD_PROFILE_DRIVERS_PERMIT_API_REQUEST:
      return {
        ...state,
        isUploadPending: true,
        isUploadError: false,
        isUploadSuccess: false,
      };

    case UPLOAD_PROFILE_DRIVERS_PERMIT_API_SUCCESS: {
      const response = action.payload;

      return {
        ...state,
        // profile: response,
        isUploadPending: false,
        isUploadError: false,
        isUploadSuccess: true,
        uploadResponse: response,
      };
    }

    case UPLOAD_PROFILE_DRIVERS_PERMIT_API_ERROR: {
      const response = action.payload;
      return {
        ...state,
        isUploadPending: false,
        isUploadError: true,
        isUploadSuccess: false,
        uploadError: response,
      };
    }

    case UPLOAD_PROFILE_ID_CARD_API_REQUEST:
      return {
        ...state,
        isUploadPending: true,
        isUploadError: false,
        isUploadSuccess: false,
      };

    case UPLOAD_PROFILE_ID_CARD_API_SUCCESS: {
      const response = action.payload;

      return {
        ...state,
        // profile: response,
        isUploadPending: false,
        isUploadError: false,
        isUploadSuccess: true,
        uploadResponse: response,
      };
    }

    case UPLOAD_PROFILE_ID_CARD_API_ERROR: {
      const response = action.payload;
      return {
        ...state,
        isUploadPending: false,
        isUploadError: true,
        isUploadSuccess: false,
        uploadError: response,
      };
    }

    case RESET_VALIDATE_PROFILE_SUCCESS: {
      return {
        ...initialState,
        validateResponse: state.validateResponse,
      };
    }
    case RESET_VERIFY_CONTACT_SUCCESS: {
      return {
        ...initialState,
        verifyResponse: state.verifyResponse,
      };
    }
    case GET_TRUSTED_DEVICES_API_REQUEST:
      return {
        ...state,
        isTrustedDevicesPending: true,
        isTrustedDevicesError: false,
        isTrustedDevicesSuccess: false,
      };

    case GET_TRUSTED_DEVICES_API_SUCCESS: {
      const response = action?.payload?.payload;

      return {
        ...state,
        isTrustedDevicesPending: false,
        isTrustedDevicesError: false,
        isTrustedDevicesSuccess: true,
        trustedDevices: response,
      };
    }

    case GET_TRUSTED_DEVICES_API_ERROR:
      return {
        ...state,
        isDeleteTrustedDevicePending: false,
        isDeleteTrustedDeviceError: true,
        isDeleteTrustedDeviceSuccess: false,
      };
    case DELETE_TRUSTED_DEVICE_API_REQUEST:
      return {
        ...state,
        isDeleteTrustedDevicePending: true,
        isDeleteTrustedDeviceError: false,
        isDeleteTrustedDeviceSuccess: false,
      };

    case DELETE_TRUSTED_DEVICE_API_SUCCESS: {
      const deleteTrustedDevice = action?.payload;
      deleteTrustedDevice._id = deleteTrustedDevice?._id || deleteTrustedDevice?.id;
      const existingDevices = [...state.trustedDevices];
      // const updatedList = existingDevices?.filter(item => item?.id !== deleteTrustedDevice?.id && item?.authId !== deleteTrustedDevice?.authId);
      const updatedList = removeItemOnce(existingDevices, deleteTrustedDevice, '_id');
      // console.log(updatedList);
      // console.log("TrustedDevice Profile", deleteTrustedDevice);

      return {
        ...state,
        deleteTrustedDevice,
        isDeleteTrustedDevicePending: false,
        isDeleteTrustedDeviceError: false,
        isDeleteTrustedDeviceSuccess: true,
        trustedDevices: updatedList,
      };
    }

    case DELETE_TRUSTED_DEVICE_API_ERROR: {
      const deleteTrustedDevice = action?.payload;
      return {
        ...state,
        isDeleteTrustedDevicePending: false,
        isDeleteTrustedDeviceError: true,
        isDeleteTrustedDeviceSuccess: false,
        deleteTrustedDevice,
      };
    }
    case GET_DOCUMENTS_API_REQUEST:
      return {
        ...state,
        isDocumentsPending: true,
        isDocumentsError: false,
        isDocumentsSuccess: false,
      };

    case GET_DOCUMENTS_API_SUCCESS: {
      const response = action?.payload?.payload;

      return {
        ...state,
        isDocumentsPending: false,
        isDocumentsError: false,
        isDocumentsSuccess: true,
        documents: response?.files,
      };
    }

    case GET_DOCUMENTS_API_ERROR:
      return {
        ...state,
        isDocumentsPending: false,
        isDocumentsError: true,
        isDocumentsSuccess: false,
      };

    case DOWNLOAD_DOCUMENT_API_REQUEST:
      return {
        ...state,
        isDownloadDocumentPending: true,
        isDownloadDocumentError: false,
        isDownloadDocumentSuccess: false,
      };

    case DOWNLOAD_DOCUMENT_API_SUCCESS: {
      const response = action?.payload?.payload;

      return {
        ...state,
        isDownloadDocumentPending: false,
        isDownloadDocumentError: false,
        isDownloadDocumentSuccess: true,
        downloadDocument: response,
      };
    }

    case DOWNLOAD_DOCUMENT_API_ERROR:
      return {
        ...state,
        isDownloadDocumentPending: false,
        isDownloadDocumentError: true,
        isDownloadDocumentSuccess: false,
      };

    default:
      return state;
  }
}

export default profileReducer;
