/* eslint-disable no-irregular-whitespace */
import { DEFAULT_KEY, generateCacheTTL } from '../../utils/functions/cache';
import {
  UPDATE_DIGITAL_SALES_CURRENT_CACHE_KEY,
  GET_DIGITAL_SALES_APPLICATIONS_API_REQUEST,
  GET_DIGITAL_SALES_APPLICATIONS_API_SUCCESS,
  GET_DIGITAL_SALES_APPLICATIONS_API_ERROR,
  DELETE_DIGITAL_SALES_APPLICATION_API_REQUEST,
  DELETE_DIGITAL_SALES_APPLICATION_API_SUCCESS,
  DELETE_DIGITAL_SALES_APPLICATION_API_ERROR,
  GET_NEXT_DIGITAL_SALES_APPLICATIONS_SUCCESS,
} from '../../constants/digitalSalesActionNames';
import {
  CLEAR_APPLICATIONS_CACHE,
  RESET_INFINITE_SCROLL_SUCCESS,
} from '../../constants/miscActionNames';
import { SELECT_CLIENTS_SUCCESS } from '../../constants/authActionNames';

import {
  dateToString,
  isoStringToDate,
  getKeys,
  uniq,
  removeItemOnce,
  getTransformPeriod,
  addArrayOnce,
} from '../../utils/functions';
import { statusOk } from '../../utils/variables';

let searchKeys = [];

const createApplicationStage = stage => {
  if (stage === 'IDVerification') {
    const formattedStage = 'ID Verification';
    return formattedStage;
  }
  return stage;
};

const createPayload = (applications, body, cacheKey) => {
  if (!Array.isArray(applications)) return null;
  searchKeys = [];
  const newApplications = applications.map((object, index) => {
    // console.log("Object", object);
    const position = index;
    const application = {
      ...object,
      index: position,
      cacheId: `${object?._id}_${cacheKey}`,
      cacheKey,
      _id: object?._id,
      id: object?._id,
      applicationId: object?.applicationId,
      applicationStage: createApplicationStage(object?.applicationStage),
      productName: object?.productName,
      currency: object?.currency,
      paid: object?.paid,
      quoteNumber: object?.quoteNumber,
      buttonEnabled: object?.buttonEnabled,
      status: object?.status,
      legacyResponse: object?.legacyResponse,
      body,
    };
    const newKeys = getKeys(application);
    searchKeys = [...searchKeys, ...newKeys];
    searchKeys = uniq(searchKeys);
    return application;
  });
  /*
  newApplications.sort((a, b) => {
    return b.createdAt > a.createdAt ? 1 : -1;
  });
  */
  return newApplications;
};

export const initialState = {
  isPending: false,
  isError: false,
  isSuccess: false,
  response: null,
  error: null,
  allRows: [],
  count: 0,
  searchKeys,
  [DEFAULT_KEY]: null,
  currentCacheKey: '',
};

function digitalSalesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DIGITAL_SALES_APPLICATIONS_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isSuccess: false,
        isError: false,
      };
    case GET_DIGITAL_SALES_APPLICATIONS_API_SUCCESS: {
      const applications = action?.payload?.payload?.applications;
      const body = action?.params;
      const cacheKey = action?.cacheKey;
      const newRows = createPayload(applications, body, cacheKey) || [];
      const oldRows = (state?.allRows && [...state?.allRows]) || [];
      const allRows = addArrayOnce(oldRows, newRows, 'cacheId');
      // const allRows = [...newRows, ...state?.allRows];
      const count = allRows.length || 0;
      const isError = action?.payload?.responseType !== statusOk;
      // console.log("applications", application);
      return {
        ...state,
        isPending: false,
        isSuccess: true,
        isError,
        response: action.payload,
        count,
        allRows,
        searchKeys,
        currentCacheKey: cacheKey,
        [DEFAULT_KEY]: {
          ...(state[DEFAULT_KEY] || {}),
          [cacheKey]: generateCacheTTL(),
        },
      };
    }
    case GET_DIGITAL_SALES_APPLICATIONS_API_ERROR:
      return {
        ...state,
        isPending: false,
        isSuccess: false,
        isError: true,
        error: action?.payload,
      };

    case UPDATE_DIGITAL_SALES_CURRENT_CACHE_KEY:
      return {
        ...state,
        currentCacheKey: action?.payload,
      };

    case DELETE_DIGITAL_SALES_APPLICATION_API_REQUEST:
      return {
        ...state,
        isDeleteDigitalSalesApplicationPending: true,
        isDeleteDigitalSalesApplicationError: false,
        isDeleteDigitalSalesApplicationSuccess: false,
      };

    case DELETE_DIGITAL_SALES_APPLICATION_API_SUCCESS: {
      const deleteDigitalSalesApplication = action?.payload?.payload?.deletedDocument;
      deleteDigitalSalesApplication.applicationId =
        deleteDigitalSalesApplication?._id ||
        deleteDigitalSalesApplication?.applicationId;
      // console.log('deleteDigitalSalesApplication: ', deleteDigitalSalesApplication);
      const existingApplications = [...state.allRows];
      // console.log('existingApplications: ', existingApplications);
      // const updatedList = existingDevices?.filter(item => item?.id !== deleteTrustedDevice?.id && item?.authId !== deleteTrustedDevice?.authId);
      const updatedList = removeItemOnce(
        existingApplications,
        deleteDigitalSalesApplication,
        'applicationId',
      );
      // console.log(updatedList);
      // console.log("Updated List", updatedList);

      return {
        ...state,
        deleteDigitalSalesApplication,
        isDeleteDigitalSalesApplicationPending: false,
        isDeleteDigitalSalesApplicationError: false,
        isDeleteDigitalSalesApplicationSuccess: true,
        allRows: updatedList,
        [DEFAULT_KEY]: null,
      };
    }

    case DELETE_DIGITAL_SALES_APPLICATION_API_ERROR: {
      const deleteDigitalSalesApplication = action?.payload;
      return {
        ...state,
        isDeleteDigitalSalesApplicationPending: false,
        isDeleteDigitalSalesApplicationError: true,
        isDeleteDigitalSalesApplicationSuccess: false,
        deleteDigitalSalesApplication,
      };
    }

    case GET_NEXT_DIGITAL_SALES_APPLICATIONS_SUCCESS: {
      const nextStartIndex = action?.payload?.nextStartIndex;
      const nextScrollCount = action?.payload?.nextScrollCount;
      const nextItemCount = action?.payload?.nextItemCount;

      return {
        ...state,

        nextItemCount,
        nextScrollCount,
        nextStartIndex,
      };
    }
    case CLEAR_APPLICATIONS_CACHE: {
      return {
        ...state,
        allRows: initialState.allRows,
        [DEFAULT_KEY]: null,
      };
    }
    case SELECT_CLIENTS_SUCCESS: {
      return {
        ...state,
        allRows: initialState.allRows,
        count: initialState.count,
        [DEFAULT_KEY]: null,
      };
    }
    case RESET_INFINITE_SCROLL_SUCCESS: {
      return {
        ...state,

        nextItemCount: 0,
        nextScrollCount: 0,
        nextStartIndex: 0,
      };
    }

    default:
      return state;
  }
}

export default digitalSalesReducer;
