export default theme => ({
  quickActionCard: props => {
    return {
      display: 'flex',
      flexDirection: 'column',
      padding: props?.product?.styles?.padding ?? theme.spacing(5),
      height: '100%',
      justifyContent: props?.product?.styles?.justifyContent ?? 'center',
      gap: theme.spacing(0.5),
      textDecoration: 'none',
      boxShadow: '-4px 4px 20px 0px #00000026',
      [theme.breakpoints.down('xsm')]: {
        padding: theme.spacing(1.75),
      },
      '&:hover': {
        '& $navigateButton': {
          transform: 'translateX(5px)',
        }/* Smooth transition effect */
      },
    };
  },

  titleStyles: props => {
    return {
      textAlign: props?.product?.styles?.textAlign ?? 'left',
      width: 'fit-content',
      fontSize: props?.product?.styles?.titleFontSize,
      paddingTop: '0 !important',
      marginTop: '0 !important',
      fontWeight: '600 !important',
      color:
        props?.product?.styles?.titleTextColor ?? theme?.palette?.inactive?.veryLight,
      lineHeight: theme.spacing(4),
      [theme.breakpoints.down('xsm')]: {
        fontSize: theme.spacing(2.5),
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
      },
      [theme.breakpoints.down('md')]: {
        fontSize: `${theme.spacing(2)}`,
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: theme.spacing(4),
      },
    };
  },
  subTitleStyles: props => {
    return {
      textAlign: props?.product?.styles?.textAlign ?? 'left',
      fontSize: props?.product?.styles?.subTitleFontSize,
      fontWeight: '400 !important',
      color: props?.product?.subTitleTextColor ?? theme?.palette?.inactive?.veryLight,
      lineHeight: theme.spacing(3),
      [theme.breakpoints.down('xsm')]: {
        fontSize: theme.spacing(1.5),
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(2),
      },
      [theme.breakpoints.down('xl')]: {
        maxWidth: theme.spacing(37.5),
      },
      [theme.breakpoints.up('xl')]: {
        maxWidth: '100%',
      },
    };
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  navigateButton: props => {
    return {
      padding: `${theme.spacing(1.5)} ${theme.spacing(0.5)}`,
      borderRadius: '0px',
      backgroundColor:
        props?.product?.styles?.buttonBackground ?? theme?.palette?.inactive?.veryLight,
      border: props?.product?.styles?.buttonBorder ?? 'none',
      minWidth: '48px',
      width: '4%',
      '&:hover': {
        backgroundColor: theme?.palette?.light?.main,
        transform: 'scale(0.98)',
      },
      '& .MuiSvgIcon-root': {
        padding: '0px',
        backgroundColor: theme?.palette?.inactive?.veryLight,
        borderRadius: '0px',
        width: '4%',
      },
    };
  },
  icon: {
    color: theme.palette.primary.main,
  },
  iconGridStyles: props => {
    if (props?.styles?.isAction) {
      return {
        display: 'flex',
        flexDirection: 'row !important',
        alignItems: 'center',
        justifyContent: 'center',
      };
    }
    return {
      display: 'flex',
      flexDirection: 'column !important',
      alignItems: 'center',
      justifyContent: 'center',
    };
  },

  titleSection: props => {
    return {
      display: 'inline-block',
    };
  },

  materialIcon: props => {
    return {
      width: 'fit-content',
      color: theme?.palette?.inactive?.veryLight,
      padding: `0 ${theme.spacing(1)}`,
      fontSize: '2rem',
    };
  },

  title: {
    marginLeft: '0 !important',
  },
  quickActionCardHeight: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between !important',
    },
    [theme.breakpoints.down('xsm')]: {
      justifyContent: 'center !important',
    },
  },
  typeTitleStyles: {
    fontSize: `${theme.spacing(3)} !important`,
    [theme.breakpoints.down('md')]: {
      fontSize: `${theme.spacing(2.5)} !important`,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: `${theme.spacing(4)} !important`,
    },
  },
  bottomNavigationButton: {
    // height: '2%',
    marginTop: theme.spacing(5),
    transition: 'all ease .5s',
  },
  moreProductColor: {
    color: `${theme.palette.primary.main} !important`,
  },
  productTypeMoreIcon: {
    color: `${theme.palette.primary.main} !important`,
  },
  moreBottomNavigationButton: {
    border: `0.063rem solid ${theme.palette.primary.main} !important`,
  },
});
