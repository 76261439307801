import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import ProductsList from '../../list/productsList';
import DutchProductsList from '../../list/productsList/dutchProductsList';
import routes from '../../../utils/variables/routes';
import { useFeatureToggle, useVariables } from '../../../hooks';
import { getRef } from '../../../utils/functions';
import { addWizard } from '../../../actions/wizards';
import { getAccessToken } from '../../../storage/accessToken';
import { dutchTemplate } from '../../../utils/variables';

const getQuickActions = ({
  variables,
  doFeatureToggle,
  dispatch,
  isAuthenticated,
  theme,
}) => {
  const isDutch = theme?.template === dutchTemplate;
  const actions = variables?.quickActions || [];
  const makePaymentFeatureActive = doFeatureToggle(`makePayment`);
  const filteredActions = isDutch
    ? actions
        .filter(x => x?.active)
        ?.filter(x => {
          if (x.isAuthRequired && isAuthenticated) {
            return true;
          }
          return !isAuthenticated && !x.isAuthRequired;
        })
    : actions.filter(x => {
        let active = x?.active;
        if (x?.hide && x?.hide === 'auth' && active) {
          active = !isAuthenticated;
        }

        return active;
      });
  return filteredActions.map(x => ({
    ...x,
    href:
      x?.href === '/make-payment' && !makePaymentFeatureActive
        ? routes.portfolio.portfolioSummary
        : x?.href,
    background: getRef(theme?.palette, x?.background),
    iconBackground: getRef(theme?.palette, x?.iconBackground),
    textColor: getRef(theme?.palette, x?.textColor),
    subTitleColor: getRef(theme?.palette, x?.subTitleColor),
  }));
};

function QuickActionBar() {
  const doFeatureToggle = useFeatureToggle();
  const dispatch = useDispatch();
  const variables = useVariables();
  const theme = useTheme();

  const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated);

  return theme.template === dutchTemplate ? (
    <DutchProductsList
      products={getQuickActions({
        variables,
        doFeatureToggle,
        dispatch,
        isAuthenticated,
        theme,
      })}
      isAction
      mobileColumnWidth={4}
    />
  ) : (
    <ProductsList
      products={getQuickActions({
        variables,
        doFeatureToggle,
        dispatch,
        isAuthenticated,
        theme,
      })}
      isAction
      mobileColumnWidth={4}
    />
  );
}

export default QuickActionBar;
