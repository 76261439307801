import {
  GET_REGISTERED_PRECERTS_SUCCESS,
  GET_REGISTERED_PRECERTS_ERROR,
  GET_REGISTERED_PRECERTS_REQUEST,
  UPDATE_PRECERTS_CURRENT_CACHE_KEY,
} from '../../constants/preCertActionNames';
import { statusOk } from '../../utils/variables';
import { DEFAULT_KEY, generateCacheTTL } from '../../utils/functions/cache';

export const initialState = {
  isPending: false,
  isError: false,
  isSuccess: false,
  isDetailsPending: false,
  isDetailsError: false,
  isDetailsSuccess: false,
  isAddPrecertError: false,
  isAddPrecertSuccess: false,
  isAddPrecertPending: false,
  response: null,
  error: null,
  allRows: [],
  details: null,
  identifier: null,
  count: 0,
  nextItemCount: 0,
  nextScrollCount: 0,
  nextStartIndex: 0,
  searchKeys: [],
  total: {},
  totalRows: 0,
  [DEFAULT_KEY]: null,
  currentCacheKey: '',
};

function registeredPrecertsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_REGISTERED_PRECERTS_REQUEST: {
      return {
        ...state,
        isSuccess: false,
        isError: false,
        isPending: true,
      };
    }
    case GET_REGISTERED_PRECERTS_SUCCESS: {
      const lobTypes = action?.lobTypes;
      const rowPayload = action?.payload?.payload?.results || [];
      const newRows = rowPayload;
      const body = action?.body;
      const lob = body?.lob;
      const cacheKey = action?.cacheKey;
      // console.log('body: ', body);
      const newTotal = action?.payload?.payload?.total || 0;
      const response = action?.payload;
      const isError = response?.responseType !== statusOk;
      // console.log('insurances: ', insurances);
      // const insuranceCollections = collections;
      const nowTime = new Date().getTime();
      const isReset = body?.reset || false;
      const incomingRows = newRows && Array.isArray(newRows) ? newRows : [];
      const oldRows =
        state?.allRows && Array.isArray(state?.allRows) ? state?.allRows : [];
      const existingRows = [...oldRows];
      // console.log('oldInsurances: ', oldInsurances);
      incomingRows.map(incomingRow => {
        // console.log('incomingRow: ', incomingRow);
        incomingRow.body = body;
        let index = 0;
        if (incomingRow.body.infiniteScroll) {
          index = existingRows.findIndex(
            oldRow =>
              oldRow.id === incomingRow.id &&
              oldRow.body.infiniteScroll === incomingRow.body.infiniteScroll &&
              oldRow.body.isMobile === incomingRow.body.isMobile &&
              oldRow.body.alpha2 === incomingRow.body.alpha2 &&
              oldRow.body?.lob?.toString() === incomingRow?.body?.lob?.toString(),
          );
        } else {
          index = existingRows.findIndex(
            oldRow =>
              oldRow.id === incomingRow.id &&
              oldRow.body.infiniteScroll === incomingRow.body.infiniteScroll &&
              oldRow.body.isMobile === incomingRow.body.isMobile &&
              oldRow.body.offset === incomingRow.body.offset &&
              oldRow.body.limit === incomingRow.body.limit &&
              oldRow.body.alpha2 === incomingRow.body.alpha2 &&
              oldRow.body?.lob?.toString() === incomingRow?.body?.lob?.toString(),
          );
        }

        // Replace item at index using native splice
        if (index !== -1) {
          existingRows.splice(index, 1, incomingRow);
        } else {
          existingRows.push(incomingRow);
        }
        return null;
      });

      const nextStartIndex = body?.nextStartIndex;
      const nextScrollCount = body?.nextScrollCount;
      const nextItemCount = body?.nextItemCount;

      const count = typeof state?.count === 'object' ? { ...state?.count } : {};
      count[cacheKey] = newTotal;

      return {
        ...state,
        total: newTotal,
        isPending: false,
        isError,
        isSuccess: true,
        response,
        searchKeys: [],
        allRows: newRows,
        count,
        currentCacheKey: cacheKey,
        [DEFAULT_KEY]: {
          ...(state[DEFAULT_KEY] || {}),
          [cacheKey]: generateCacheTTL(),
        },
        nextItemCount,
        nextScrollCount,
        nextStartIndex,
        incomingRows,
      };
    }
    case GET_REGISTERED_PRECERTS_ERROR: {
      return {
        ...state,
        isSuccess: false,
        isError: true,
        isPending: false,
        error: action?.payload,
      };
    }

    case UPDATE_PRECERTS_CURRENT_CACHE_KEY:
      return {
        ...state,
        currentCacheKey: action?.payload,
      };
    default:
      return state;
  }
}

export default registeredPrecertsReducer;
