export const UPDATE_QUOTES_CURRENT_CACHE_KEY = 'UPDATE_QUOTES_CURRENT_CACHE_KEY';
export const GET_QUOTES_API_REQUEST = 'GET_QUOTES_API_REQUEST';
export const GET_QUOTES_API_SUCCESS = 'GET_QUOTES_API_SUCCESS';
export const GET_QUOTES_API_ERROR = 'GET_QUOTES_API_ERROR';
export const GET_NEXT_QUOTES_SUCCESS = 'GET_NEXT_QUOTES_SUCCESS';
export const UPDATE_QUOTES_TOTAL = 'UPDATE_QUOTES_TOTAL';
export const UPDATE_QUOTES_STATUS = 'UPDATE_QUOTES_STATUS';
export const DELETE_QUOTES_API_REQUEST = 'DELETE_QUOTES_API_REQUEST';
export const DELETE_QUOTES_API_SUCCESS = 'DELETE_QUOTES_API_SUCCESS';
export const DELETE_QUOTES_API_ERROR = 'DELETE_QUOTES_API_ERROR';
