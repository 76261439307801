export default theme => ({
  dutchItem: () => ({
    width: theme.spacing(37.5),
    height: theme.spacing(32.8),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(25),
      flexGrow: 1,
    },
    [theme.breakpoints.down('xsm')]: {
      height: theme.spacing(27.5),
      width: theme.spacing(20),
      paddingRight: 0,
    },
  }),
  dutchItemContainer: {
    padding: theme.spacing(0.6),
    [theme.breakpoints.up('xl')]: {
      paddingTop: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center',
    },
  },
});
