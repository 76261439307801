import { useSelector } from 'react-redux';
import {
  authSearchCategories,
  notAuthSearchCategories,
  agentSearchCategories,
} from '../utils/variables/searchCategories';
import { agentType } from '../utils/variables';
import { addItemOnce } from '../utils/functions';

const useSearchCategories = ({ overrides = [] } = {}) => {
  const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated);
  const isAgent = useSelector(
    state => state?.auth?.user?.agent?.id && state?.auth?.usertype === agentType,
  );
  let searchCategories = [...notAuthSearchCategories];
  if (isAuthenticated) {
    searchCategories = [...authSearchCategories];
  }
  if (isAgent) {
    searchCategories = [...agentSearchCategories];
  }
  if (overrides && overrides.length > 0) {
    overrides.map(override => {
      searchCategories = addItemOnce(searchCategories, override, 'value');
    });
  }

  return [...searchCategories];
};

export default useSearchCategories;
