import React from 'react';
import { Link as RouterLink, Redirect, useHistory, useLocation } from 'react-router-dom';
import {
  Typography,
  Grid,
  // ButtonBase,
  Button,
  // Link,
  Card,
  Skeleton,
  CardActions,
  CardContent,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import routes from '../../../utils/variables/routes';
import { investmentLob, agentType, domain } from '../../../utils/variables';
import { addWizard } from '../../../actions/wizards';
import { useRouter, useVariables } from '../../../hooks';
import Header from './header';
import styles from './style';
// import { formatImageUrlFromStrapi } from '../../../utils/functions';
import { ImageBox } from '../../common';
import ContentBox from './content';
import { selectedProduct } from '../../../actions/auth';
import fatumRedirectApi from '../../../apis/auth/getFatumRedirectApi';
import { sourceTextFromData } from '../../../utils/functions';

const ProductCard = ({ product, loading, isParentProduct }) => {
  const [openDialogBox, setOpenDialogBox] = React.useState(false);
  // console.log('product: ', product);
  const productLink = product?.backlinks ? product.backlinks[0]?.url : '';
  const useStyles = makeStyles(theme => styles(theme));
  const classes = useStyles();
  const location = useLocation();
  const router = useRouter();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const variables = useVariables();

  const selectedClient = useSelector(state => state?.auth?.selectedClient);
  const parentLob = useSelector(state => state?.products?.product?.filterLob);
  const checkedProducts = useSelector(state => state?.auth?.selectedProduct);

  const domains = {
    ...domain,
    ...(variables?.domains || {}),
  };
  const fatumRedirectLinks = sourceTextFromData(
    { domains },
    variables?.fatumRedirectLinks,
  );

  const referralId = useSelector(state => {
    return state.temp.referralId;
  });

  const productImage =
    product?.featuredImage_pwa ||
    (product?.parentProducts && product?.parentProducts[0]?.featuredImage_pwa);

  const isInvest = false;

  const isAgent = useSelector(
    state => state?.auth?.user?.agent?.id && state?.auth?.usertype === agentType,
  );
  const accessToken = useSelector(state => state?.auth?.user?.accessToken);

  const state = useSelector(state => state);

  const ggfatumLink = sourceTextFromData({ domains }, fatumRedirectLinks?.quote);
  const agentPortalTakeInsuranceUrl = sourceTextFromData(
    { domains },
    fatumRedirectLinks?.agentPortalTakeInsurance,
  );
  const agentPortalQuoteURL = sourceTextFromData(
    { domains },
    fatumRedirectLinks?.agentPortalQuoteURL,
  );
  const agentPortalCalculatePremiumUrl = sourceTextFromData(
    { domains },
    fatumRedirectLinks?.agentPortalCalculatePremium,
  );

  const getFatumRedirectUrlWithSessionId = async slugUrl => {
    const redirectUrl = new URL(
      isAgent ? `${agentPortalTakeInsuranceUrl}` : `${ggfatumLink}/${slugUrl}`,
    );
    let sessionId = null;
    if (product?.productCode) {
      redirectUrl.searchParams.append('product', product.productCode);
    }
    if (accessToken) {
      const res = await fatumRedirectApi.getSessionId(state, {
        customerMasterId: selectedClient ? selectedClient?.masterId : null,
        customerC360Id: selectedClient ? selectedClient?.c360Id : null,
      });
      sessionId = res?.payload?.sessionId;
      if (sessionId) {
        redirectUrl.searchParams.append('sessionid', sessionId);
      }
    }
    if (referralId) {
      redirectUrl.searchParams.append('360_referrer', referralId);
    }
    return redirectUrl;
  };

  const getFatumQuotesUrlWithSessionId = async slugUrl => {
    let sessionId = null;
    const redirectURL = new URL(
      isAgent ? `${agentPortalQuoteURL}` : `${ggfatumLink}/${slugUrl}`,
    );
    if (product?.productCode) {
      redirectURL.searchParams.append('product', product.productCode);
    }
    if (accessToken) {
      const res = await fatumRedirectApi.getSessionId(state, {
        customerMasterId: selectedClient ? selectedClient?.masterId : null,
        customerC360Id: selectedClient ? selectedClient?.c360Id : null,
      });
      sessionId = res?.payload?.sessionId;
      if (sessionId) {
        redirectURL.searchParams.append('sessionid', sessionId);
      }
    }
    if (referralId) {
      redirectURL.searchParams.append('360_referrer', referralId);
    }
    return redirectURL;
  };

  const getFatumCalculatePremiumUrlWithSessionId = async slugUrl => {
    let sessionId = null;
    const redirectURL = new URL(
      isAgent ? `${agentPortalCalculatePremiumUrl}` : `${ggfatumLink}/${slugUrl}`,
    );
    if (isAgent || accessToken) {
      const res = await fatumRedirectApi.getSessionId(state, {
        customerMasterId: selectedClient ? selectedClient?.masterId : null,
        customerC360Id: selectedClient ? selectedClient?.c360Id : null,
      });
      sessionId = res?.payload?.sessionId;
      if (sessionId) {
        redirectURL.searchParams.append('sessionid', sessionId);
      }
    }
    if (product?.productCode) {
      redirectURL.searchParams.append('product', product.productCode);
    }
    return redirectURL;
  };

  const handleProductSelect = product => {
    if (checkedProducts?.id === product?.id) {
      dispatch(selectedProduct.selectedProductSuccess(null));
    } else {
      dispatch(selectedProduct.selectedProductSuccess(product));
    }
  };

  return (
    <Card className={classes.card}>
      {isAgent && !isParentProduct && (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                onClick={() =>
                  handleProductSelect({
                    id: product?.id,
                    title: product?.title,
                    productCode: product?.productCode,
                    slug: product?.quoteWizard?.slug,
                  })
                }
                checked={checkedProducts?.id == product?.id}
              />
            }
            label="Select to purchase"
          />
        </FormGroup>
      )}

      <CardContent className={classes.cardContent}>
        <ContentBox
          open={openDialogBox}
          handleClose={setOpenDialogBox}
          title={product?.title}
          content={product?.content ? product?.content : product?.description}
          isHtmlContent={product?.content}
        />
        <Grid container direction="column" spacing={1}>
          <Grid item xs={12} direction="column" className={classes.zGrid}>
            {loading ? (
              <Skeleton variant="rect" height={190} />
            ) : (
              <ImageBox
                {...productImage}
                isImageStyleGlobal
                imageStyle={{ height: '190px' }}
                fixedSettings={{
                  screenSize: { sm: 'sm', md: 'sm', lg: 'sm' },
                  fit: { sm: 'width', md: 'width', lg: 'width' },
                }}
                imageBorderRadius={theme.shape.borderRadius}
              />
            )}
          </Grid>

          <Grid item xs={12} className={classes.zGrid}>
            <Grid
              container
              spacing={1}
              justifyContent="space-between"
              direction="column"
              className={classes.gridPad}
            >
              <Grid item xs={12}>
                {loading ? (
                  <Skeleton
                    animation="wave"
                    height={40}
                    width="100%"
                    style={{ marginBottom: 6 }}
                  />
                ) : (
                  <Typography
                    gutterBottom
                    textAlign="left"
                    variant="body1"
                    color="text.main"
                    component="div"
                    fontWeight="600"
                  >
                    {product?.title}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                {loading ? (
                  <>
                    <Skeleton
                      animation="wave"
                      height={20}
                      width="90%"
                      style={{ marginBottom: 6 }}
                    />
                    <Skeleton
                      animation="wave"
                      height={20}
                      width="90%"
                      style={{ marginBottom: 6 }}
                    />
                    <Skeleton
                      animation="wave"
                      height={20}
                      width="90%"
                      style={{ marginBottom: 6 }}
                    />
                    <Skeleton
                      animation="wave"
                      height={20}
                      width="90%"
                      style={{ marginBottom: 6 }}
                    />
                  </>
                ) : (
                  <>
                    <Typography
                      variant="subTitle2"
                      align="left"
                      gutterBottom
                      color="text.dark"
                      className={classes.description}
                    >
                      {
                        product?.description /* product?.hideDescriptionOnPWA && product?.isProductType ? null : */
                      }
                    </Typography>
                    {product?.description && (
                      <Typography
                        align="right"
                        onClick={() => setOpenDialogBox(true)}
                        className={classes.readMore}
                      >
                        Read more...
                      </Typography>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Grid container justifyContent="flex-end" spacing={1}>
          {product?.quoteWizard?.slug && (
            <Grid item xs={6} align="center" className={classes.zGrid}>
              {loading ? (
                <Skeleton
                  variant="text"
                  animation="wave"
                  height={50}
                  width="80%"
                  className={classes.button}
                />
              ) : (
                <Button
                  justifyContent="center"
                  // size="small"
                  className={classes.secondaryButton}
                  // startIcon={<AssignmentIcon />}
                  onClick={async () => {
                    if (!accessToken) {
                      const notification = 'Please sign in or create a new account';
                      window.localStorage.setItem('loginNotification', notification);
                    }
                    if (isAgent && !selectedClient) {
                      window.location.href = routes.clients.index;
                      localStorage.setItem(
                        'createClientNotification',
                        'Please select a client or create a new client',
                      );
                      return;
                    }
                    if (!isParentProduct && !(parentLob === 'other')) {
                      if (accessToken) {
                        window.location.href = await getFatumQuotesUrlWithSessionId(
                          product?.quoteWizard?.slug,
                        );
                      }
                    } else {
                      dispatch(addWizard.addLastEntryPointAction(router?.asPath));
                    }
                  }}
                  component={RouterLink}
                  to="/auth/sign-in"
                >
                  {isInvest ? 'Get Advice' : 'Get Quote'}
                </Button>
              )}
            </Grid>
          )}
          {product?.buyOnlineWizard?.slug && (
            <Grid item xs={6} align="center" className={classes.zGrid}>
              {loading ? (
                <Skeleton
                  variant="text"
                  animation="wave"
                  height={50}
                  width="80%"
                  className={classes.button}
                />
              ) : (
                <Button
                  justifyContent="center"
                  disabled={loading}
                  className={classes.secondaryButton}
                  component={RouterLink}
                  onClick={() => {
                    dispatch(addWizard.addLastEntryPointAction(router?.asPath));
                  }}
                  to={`${routes.wizards.index}/${product?.buyOnlineWizard?.slug}`}
                  // startIcon={<AddShoppingCartIcon />}
                >
                  Purchase Now
                </Button>
              )}
            </Grid>
          )}
          <Grid item xs={6} align="center" className={classes.zGrid}>
            {loading ? (
              <Skeleton
                variant="text"
                animation="wave"
                height={50}
                width="80%"
                className={classes.button}
              />
            ) : (
              <Button
                color="primary"
                justifyContent="center"
                // size="small"
                className={classes.primaryButton}
                // startIcon={<InfoIcon />}
                component={RouterLink}
                onClick={
                  !isParentProduct && !(parentLob === 'other')
                    ? async () => {
                        if (!accessToken) {
                          const notification = 'Please sign in or create a new account';
                          window.localStorage.setItem('loginNotification', notification);
                        }
                        if (isAgent && !selectedClient) {
                          window.location.href = routes.clients.index;
                          localStorage.setItem(
                            'createClientNotification',
                            'Please select a client or create a new client',
                          );
                          return;
                        }
                        if (accessToken) {
                          window.location.href = await getFatumRedirectUrlWithSessionId(
                            product?.quoteWizard?.slug,
                          );
                        }
                      }
                    : undefined
                }
                to={
                  parentLob === 'other' && !isParentProduct
                    ? `${routes.products.index}/${product?.slug}/contact-us?title=${product?.title}`
                    : !isParentProduct && !accessToken
                    ? '/auth/sign-in'
                    : `${routes.products.index}/${product?.slug}`
                }
              >
                {parentLob === 'other' && !isParentProduct
                  ? 'Contact Us'
                  : !isParentProduct
                  ? 'Purchase'
                  : 'Learn More'}
              </Button>
            )}
          </Grid>

          <Grid item xs={12} md={12} align="center" className={classes.zGrid}>
            {loading ? (
              <Skeleton
                variant="text"
                animation="wave"
                height={50}
                width="80%"
                className={classes.button}
              />
            ) : (
              <Button
                justifyContent="center"
                // size="small"
                className={classes.secondaryButton}
                // startIcon={<AssignmentIcon />}
                onClick={async () => {
                  window.location.href = await getFatumCalculatePremiumUrlWithSessionId(
                    product?.quoteWizard?.slug,
                  );
                }}
                to={location.pathname}
                component={RouterLink}
                fullWidth
              >
                Calculate Premium
              </Button>
            )}
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default ProductCard;
