// Hook

import { useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { useAuthValidation } from './useAuth';
import useWindowSize from './useWindowSize';
import {
  mainHeaderHeight,
  dutchMainHeaderHeight,
  mainMobileHeaderHeight,
  mainMobileNotAuthHeaderHeight,
  mainFooterHeight,
  bottomNavHeight,
  agentType,
  mainLaptopHeaderHeight,
} from '../utils/variables';

const useMainContentHeight = ({
  additionalHeight = '0px',
  addHeader = true,
  addFooter = true,
  addBottomNav = true,
} = {}) => {
  // const theme = useTheme();
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme => theme.breakpoints.down('lg'));
  const auth = useAuthValidation();
  const size = useWindowSize();
  const isAgent = useSelector(
    state => state?.auth?.user?.agent?.id && state?.auth?.usertype === agentType,
  );
  // // console.log('size: ', size);

  const newBottomNavHeight =
    !isAgent && auth && addBottomNav && isSmall ? bottomNavHeight : '0px';
  // eslint-disable-next-line no-nested-ternary
  const headerChosenHeight = isSmall
    ? auth
      ? mainMobileHeaderHeight
      : mainMobileNotAuthHeaderHeight
    : mainHeaderHeight;
  // // console.log('headerChosenHeight: ', headerChosenHeight);
  const headerHeight = addHeader ? headerChosenHeight : '0px';
  const footerHeight = addFooter ? mainFooterHeight : '0px';
  const bottomNavValue = parseInt(newBottomNavHeight, 10);
  const headerValue = parseInt(headerHeight, 10);
  const footerValue = parseInt(footerHeight, 10);
  const additionalHeightValue = parseInt(additionalHeight, 10);

  const viewportHeightValue = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0,
  );
  const viewportHeight = viewportHeightValue ? `${viewportHeightValue}px` : '100vh';
  // console.log('viewportHeight: ', viewportHeight);

  const newHeight = `calc(${viewportHeight} - (${headerHeight} + ${additionalHeight} + ${footerHeight} +
    ${newBottomNavHeight} ))`;

  const newHeightValue =
    size.height - (headerValue + additionalHeightValue + footerValue + bottomNavValue);
  return {
    contentHeight: `${newHeight} !important`,
    singleContentHeight: newHeight,
    contentValue: newHeightValue,
    bottomNavHeight: newBottomNavHeight,
    headerHeight,
    dutchHeaderHeight: isMedium ? mainMobileHeaderHeight : dutchMainHeaderHeight,
    footerHeight,
    additionalHeight,
    bottomNavValue,
    headerValue,
    footerValue,
    additionalHeightValue,
    viewportHeight,
    viewportHeightValue,

  };
};

export default useMainContentHeight;
