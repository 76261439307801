export default theme => ({
  container: {
    //paddingLeft: '1.5rem',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      gap: '1rem',
    },
    [theme.breakpoints.up('sm')]: {
      gap: '0.5rem',
    },
    margin: '2.5rem 0rem 0 0rem',
  },
  image: {
    borderRadius: '50%',
    // width: '70%',
  },
  contactContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '1rem',
    marginTop: '0.5rem',
  },
  title: {
    fontSize: '1.25rem',
    lineHeight: '1.25rem',
    // width: '6.43rem',
    // height: '1.25rem',
    fontWeight: 600,
    marginBottom: '0.31rem',
  },
  contactItem: {
    border: `${theme.shape.borderWidth}px solid ${theme.palette.primary.main}`,
    display: 'flex',
    justifyContent: 'center',
    gap: '0.5rem',
    alignItems: 'center',
    borderRadius: '5px',
    padding: '0.3rem',
  },
  contactText: {
    color: theme.palette.primary.main,
    fontSize: '0.875rem',
    fontWeight: 600,
    lineHeight: '1.25rem',
  },
  chatText: {
    color: theme.palette.primary.main,
    fontSize: '0.875rem',
    fontWeight: 600,
    lineHeight: '1.25rem',
  },
  // contactElement: {
  //   color: theme.palette.primary.main,
  //   padding: '0.25rem',
  //   fontSize: '14px',
  // },
  helpText: {
    fontSize: '0.875rem',
    lineHeight: '1.12rem',
    fontWeight: 400,
  },
  contactIcon: {
    color: theme.palette.primary.main,
    margin: '0.1rem 0rem 0rem 0rem',
    fontSize: '0.95rem',
    // fontWeight: 400,
  },
  chatIcon: {
    marginTop: '0.18rem',
    left: '0.15rem',
    fontSize: '0.95rem',
    color: theme.palette.primary.main,
    //fontWeight: 400,
  },
});
