import { portfolioButtonStyles } from '../../../styles';

const styles = theme => ({
  ...portfolioButtonStyles(theme),
  paper: {
    // backgroundColor: theme.palette.background.banner,
    padding: theme.spacing(2),
    maxWidth: 400,
  },
});

export default styles;
