import {
  UPDATE_QUOTES_CURRENT_CACHE_KEY,
  GET_QUOTES_API_REQUEST,
  GET_QUOTES_API_SUCCESS,
  GET_QUOTES_API_ERROR,
  GET_NEXT_QUOTES_SUCCESS,
  UPDATE_QUOTES_TOTAL,
} from '../../../constants/quoteActionNames';
import { agentType } from '../../../utils/variables';
import { createRelativeUrl } from '../../../utils/functions';
import { checkCacheValid } from '../../../utils/functions/cache';
import { getQuotesUrl } from '../../../apis/quotes/getQuotesApi';
import { getQuotesApi } from '../../../apis/quotes';
import { getPayloadResponse } from '../../../reducers/quotes';

function updateCacheKey(cacheKey) {
  return {
    type: UPDATE_QUOTES_CURRENT_CACHE_KEY,
    payload: cacheKey,
  };
}

function getQuotesRequest() {
  return {
    type: GET_QUOTES_API_REQUEST,
    payload: true,
  };
}

function getQuotesSuccess(quotes, params, cacheKey, lobTypes) {
  return {
    type: GET_QUOTES_API_SUCCESS,
    payload: quotes,
    params,
    lobTypes,
    cacheKey,
  };
}

function updateQuotesTotal(params, cacheKey, lobTypes) {
  return {
    type: UPDATE_QUOTES_TOTAL,
    payload: [],
    params,
    lobTypes,
    cacheKey,
  };
}

function getQuotesError(err) {
  return {
    type: GET_QUOTES_API_ERROR,
    payload: err,
  };
}

export function getQuotesAction(params) {
  // const response = getPayloadResponse();
  return (dispatch, getState) => {
    const state = getState();
    params.alpha2 = state?.locations?.yourLocation?.alpha2;
    const cacheKey = createRelativeUrl({
      path: getQuotesUrl,
      queryObject: params,
    });

    const usertype = state?.auth?.usertype;
    const lobTypes = state?.locations?.lobCategories;
    let filteredLobCategories = [];
    if (usertype === agentType) {
      filteredLobCategories = lobTypes.filter(x => x.isAvailableForAgentClaims);
    } else {
      filteredLobCategories = lobTypes.filter(x => x.isAvailableForCustomerClaims);
    }

    const isCacheValid = checkCacheValid(getState, 'quotes', cacheKey);
    if (isCacheValid) {
      // dispatch(getClaimsSuccess(null, params, cacheKey, filteredLobCategories));
      dispatch(updateCacheKey(cacheKey));
      dispatch(updateQuotesTotal(params, cacheKey, filteredLobCategories));
      return Promise.resolve(null);
    }

    dispatch(getQuotesRequest());
    return getQuotesApi
      .getQuotes(state, params)
      .then(data => {
        dispatch(getQuotesSuccess(data, params, cacheKey, filteredLobCategories));
        return data;
      })
      .catch(error => {
        dispatch(getQuotesError(error, params));
        throw error;
        // return response;
      });
  };
}

function getNextQuotesSuccess(prod) {
  return {
    type: GET_NEXT_QUOTES_SUCCESS,
    payload: prod,
  };
}

export function getNextQuotesAction(data) {
  return getNextQuotesSuccess(data);
}

export default { getQuotesAction, getNextQuotesAction };
