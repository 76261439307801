import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Icon, Box, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useSmall } from '../../../../hooks';
import { Spinner } from '../../../common';
import styles from './style';
import fatumRedirectApi from '../../../../apis/auth/getFatumRedirectApi';
import { isValidHttpUrl } from '../../../../utils/functions';

const useStyles = makeStyles(styles);

function DutchCategoryCard(props) {
  const { product, loading } = props;
  const { background, materialIcon, subTitle, title, href, isExternalLink } = product;
  const isSmall = useSmall();
  const getProductType = product.type === 'action' || '';
  const classes = useStyles({ ...props, isSmall });
  const { productTypeMore } = props;
  const selectedClient = useSelector(state => state?.auth?.selectedClient);
  const state = useSelector(state => state);

  const onClick =
    product.onClick ||
    async function onClick() {
      if (isExternalLink) {
        await redirectToExternalSite(href);
      }
    };

  async function redirectToExternalSite(href) {
    if (isValidHttpUrl(href)) {
      const redirectUrl = new URL(href);
      const res = await fatumRedirectApi.getSessionId(state, {
        customerMasterId: selectedClient ? selectedClient?.masterId : null,
        customerC360Id: selectedClient ? selectedClient?.c360Id : null,
      });
      const sessionId = res?.payload?.sessionId;
      if (sessionId) {
        redirectUrl.searchParams.append('sessionid', sessionId);
      }
      window.location.href = redirectUrl;
    }
  }

  return (
    <Grid
      className={classNames(
        classes.quickActionCard,
        !getProductType ? classes.quickActionCardHeight : classes.insuranceQuickAction,
      )}
      bgcolor={{ background }}
      component={RouterLink}
      to={href}
      onClick={onClick}
    >
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        <Grid item>
          {materialIcon && (
            <Icon
              data-nosnippet
              item
              className={`${classes.materialIcon} ${productTypeMore &&
                classes.productTypeMoreIcon}`}
            >
              {materialIcon}
            </Icon>
          )}
        </Grid>
        <Grid item sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {title && (
            <>
              {productTypeMore ? (
                <Typography
                  item
                  className={classNames(
                    classes.titleStyles,
                    classes.typeTitleStyles,
                    classes.moreProductColor,
                  )}
                >
                  {title}
                </Typography>
              ) : (
                <Typography
                  className={`${classes.titleStyles} ${!getProductType &&
                    classes.typeTitleStyles}`}
                >
                  {title}
                </Typography>
              )}
            </>
          )}
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          // overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {subTitle && (
          <div>
            {loading ? (
              <Spinner />
            ) : (
              <Typography title={subTitle} className={classes.subTitleStyles}>
                {subTitle}
              </Typography>
            )}
          </div>
        )}
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          component={RouterLink}
          to={href}
          className={`${classes.navigateButton} ${
            !getProductType
              ? classes.bottomNavigationButton
              : classes.bottomNavigationButton
          }`}
          onClick={onClick}
        >
          <Icon
            data-nosnippet
            className={`${classes.icon} ${!getProductType && classes.typeIcon}`}
          >
            chevron_right
          </Icon>
        </Button>
      </Grid>
    </Grid>
  );
}

export default DutchCategoryCard;
