import { Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import { Spinner, Loading } from '../../common';
import { splitCamelCaseToString } from '../../../utils/functions';
import styles from './style';

const useStyles = makeStyles(styles);
let lastScroll = null;
const MobileListRenderer = ({
  cardData,
  CardComponent,
  loadMoreRows,
  options,
  count,
  displayName,
  isPending,
  isError,
  isSuccess,
  hidePagination,
}) => {
  const classes = useStyles();
  useEffect(() => {
    if (lastScroll) {
      window.scrollTo(lastScroll[0], lastScroll[1]);
      lastScroll = null;
    }
  }, [cardData.length]);

  const getScroll = () => {
    if (window.pageYOffset !== undefined) {
      return [window.pageXOffset, window.pageYOffset];
    }
    let sx = 0;
    let sy = 0;
    const d = document;
    const r = d.documentElement;
    const b = d.body;
    sx = r.scrollLeft || b.scrollLeft || 0;
    sy = r.scrollTop || b.scrollTop || 0;
    return [sx, sy];
  };

  const listItems = cardData.map(cardItemData => {
    return (
      <Grid item>
        <CardComponent row={cardItemData} />
      </Grid>
    );
  });

  const onShowMoreClicked = () => {
    lastScroll = getScroll();
    const startIndex = cardData.length;
    // console.log('startIndex: ', startIndex);
    // console.log('options: ', options);
    loadMoreRows({
      nextStartIndex: startIndex,
      nextItemCount: options?.itemCount,
      nextScrollCount: startIndex + options?.itemCount,
      actionIndex: 1,
    });
  };

  if (isPending && cardData?.length === 0) {
    return (
      <Loading
        secondaryTitle={`Retrieving your ${splitCamelCaseToString(displayName)}`}
        loadingHeight="30vh"
      />
    );
  }

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item xs={12}>
        <Grid container direction="column" spacing={1}>
          {listItems}
        </Grid>
      </Grid>
      {!hidePagination && (
        <Grid item>
          {isPending ? (
            <Spinner
              secondaryTitle={`Retrieving your ${splitCamelCaseToString(displayName)}`}
            />
          ) : listItems.length < count ? (
            <Button className={classes.secondaryButton} onClick={onShowMoreClicked}>
              Show More
            </Button>
          ) : (
            <Typography
              sx={{ color: 'text.label' }}
            >{`You have no more ${displayName} items available.`}</Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default MobileListRenderer;
