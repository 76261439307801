export const RESET_STATE = 'RESET_STATE';
export const SET_MODAL_CONTENT_SUCCESS = 'SET_MODAL_CONTENT_SUCCESS';
export const OPEN_CHAT_BOT_SUCCESS = 'OPEN_CHAT_BOT_SUCCESS';
export const RESET_INFINITE_SCROLL_SUCCESS = 'RESET_INFINITE_SCROLL_SUCCESS';
export const STORE_POLICY_SUCCESS = 'STORE_POLICY_SUCCESS';
export const UPDATE_YOUR_STORAGE_VERSION_SUCCESS = 'UPDATE_YOUR_STORAGE_VERSION_SUCCESS';
export const RESET_EXPIRED_SUCCESS = 'RESET_EXPIRED_SUCCESS';
export const RESET_RENEWAL_ORDER_SUCCESS = 'RESET_RENEWAL_ORDER_SUCCESS';
export const RESET_ADD_PREMIUM_TO_CART_SUCCESS = 'RESET_ADD_PREMIUM_TO_CART_SUCCESS';
export const CHANGE_REDIRECT_SUCCESS = 'CHANGE_REDIRECT_SUCCESS';
export const GET_GENERIC_CONTENTS_API_REQUEST = 'GET_GENERIC_CONTENTS_API_REQUEST';
export const GET_GENERIC_CONTENTS_API_SUCCESS = 'GET_GENERIC_CONTENTS_API_SUCCESS';
export const GET_GENERIC_CONTENTS_API_ERROR = 'GET_GENERIC_CONTENTS_API_ERROR';
export const ADD_TRUSTED_DEVICE_API_SUCCESS = 'ADD_TRUSTED_DEVICE_API_SUCCESS';
export const STORE_CAMPAIGN_SUCCESS = 'STORE_CAMPAIGN_SUCCESS';
export const SET_LAST_PAID_ORDER_ID_SUCCESS = 'SET_LAST_PAID_ORDER_ID_SUCCESS';
export const GET_SURVEY_API_REQUEST = 'GET_SURVEY_API_REQUEST';
export const GET_SURVEY_API_SUCCESS = 'GET_SURVEY_API_SUCCESS';
export const GET_SURVEY_API_ERROR = 'GET_SURVEY_API_ERROR';
export const CLEAR_PORTFOLIO_CACHE = 'CLEAR_PORTFOLIO_CACHE';
export const CLEAR_RECEIPTS_CACHE = 'CLEAR_RECEIPTS_CACHE';
export const CLEAR_QUOTES_CACHE = 'CLEAR_QUOTES_CACHE';
export const CLEAR_CLAIMS_CACHE = 'CLEAR_CLAIMS_CACHE';
export const CLEAR_APPLICATIONS_CACHE = 'CLEAR_APPLICATIONS_CACHE';
export const CLEAR_CART_CACHE = 'CLEAR_CART_CACHE';
export const CLEAR_INCOMPLETE_CLAIMS_CACHE = 'CLEAR_INCOMPLETE_CLAIMS_CACHE';
