import {
  DUTCH_PAYMENT_STATUS_ERROR,
  DUTCH_PAYMENT_STATUS_SUCCESS,
  DUTCH_PAYMENT_STATUS_REQUEST,
} from '../../constants/dutchPaymentActionNames';

export const initialState = {
  isFetchingDetails: false,
  payload: null,
  error: null,
};

function paymentStatusReducer(state = initialState, action) {
  switch (action.type) {
    case DUTCH_PAYMENT_STATUS_REQUEST: {
      return {
        ...state,
        isFetchingDetails: true,
      };
    }
    case DUTCH_PAYMENT_STATUS_SUCCESS: {
      return {
        ...state,
        isFetchingDetails: false,
        payload: action.payload,
        error: null,
      };
    }
    case DUTCH_PAYMENT_STATUS_ERROR: {
      return {
        ...state,
        isFetchingDetails: false,
        payload: action.payload,
        error: true,
      };
    }
    default:
      return state;
  }
}

export default paymentStatusReducer;
