export const lightModeLogo = '/images/lightModeLogo.webp';
export const darkModeLogo = '/images/darkModeLogo.webp';
export const oldLightModeLogo = '/images/old_LightModeLogo.png';
export const strip = '/images/strip.png';
export const guardianBuilding = '/images/GGBuilding.png';
export const instagram = '/images/instagram.png';
export const snapchat = '/images/snapchat.png';
export const linkedin = '/images/linkedin.png';
export const gmail = '/images/gmail.png';
export const defaultProfileImage = '/images/defaultProfilePhoto.png';
export const ggIconOnly = '/images/ggIconOnly.png';
// export const poweredByFac = '/images/poweredByFAC.jpg';
export const poweredByFac = '/images/poweredByFAC1.png';

export const noPreviewImage = '/images/noPreview.jpg';
export const userHome = '/images/userHome.png';
export const userHomeSM = '/images/userHome_sm.png';
export const userHomeXS = '/images/userHome_xs.png';
export const agentHome = '/images/agentHome.png';
export const agentHomeSM = '/images/agentHome_sm.png';
export const agentHomeXS = '/images/agentHome_xs.png';
export const supportAgent = '/images/support_agent.svg';
export const supportAgent2 = '/images/support_agent2.svg';

export const usFlag = '/images/flags/usa.svg';

export const defaultImage = '/images/GGplaceholder.webp';
export const siteOffline = '/images/siteOffline.png';

export const referralImage = '/images/referral.png';

export const recommendationEngine = '/images/recommendationEngine.png';

export const feedbackImage = '/images/feedback.png';

export const buyersGuideImage = '/images/buyersGuide.png';

export const uploadFileError = '/images/icons/ExcludeUploadFail.svg';
export const uploadFileSuccess = '/images/icons/ShapeuploadSuccess.svg';

// Help Center Home
export const coverImage = '/images/helpCenter/cover.png';
export const lifeInsurance = '/images/helpCenter/lifeInsurance.png';
export const health = '/images/helpCenter/health.png';
export const pensionInsurance = '/images/helpCenter/pensionInsurance.png';
export const call = '/images/helpCenter/call.svg';
export const chat = '/images/helpCenter/chat.svg';
// Help Center InProgress
export const path1 = '/images/helpCenter/InProgress/path.svg';
export const path2 = '/images/helpCenter/InProgress/path2.svg';

// Help Center GLL
export const gettingStarted = '/images/helpCenter/gettingStarted.png';
export const beneficiary = '/images/helpCenter/beneficiary.png';
export const disputeResolution = '/images/helpCenter/disputeResolution.png';
